import {ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Icon from 'react-native-vector-icons/FontAwesome';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {
  DateTime,
  dhlBlack,
  dhlBlue,
  dhlGrey,
  dhlRed,
  dhlRedButton,
  dhlStatusGreen,
  dhlWhiteBasic,
  navigationPushAction,
} from '@smartops/smartops-shared';
import {
  getFilterMaxRows,
  getNotificationFiler,
  getNotificationSeverities,
  getNotificationGroups,
  setNotificationFilter,
} from '../flows/applicationNotification';
import {NotificationGroupData, NotificationFilter, NotificationSeverity, NotificationType} from '../utils/types';
import I18n from '../features/I18n';
import i18n from '../features/I18n';
import {FilteredEnumType, NotificationFilterEnumPickerProps} from './NotificationFilterEnumPickerScreen';

interface NotificationFilterProps {
  enumPickerData: NotificationFilterEnumPickerProps | undefined;
}

export interface SelectableItem {
  label: string;
  name: string;
  selected: boolean;
}

type SetValue = {
  label: string;
  dataToUpdate: SelectableItem[];
  setterFunction(items: SelectableItem[]): void;
};

export const ALL_OPTION = 'ALL';
const customGrey = '#e8e8e8';

const NotificationFilterScreen = (props: NotificationFilterProps) => {
  const dispatch = useDispatch();
  const numberOfLines: number = useSelector(getFilterMaxRows);
  const severitiesData: NotificationSeverity[] = useSelector(getNotificationSeverities);
  const notificationFilter: NotificationFilter | undefined = useSelector(getNotificationFiler);
  const typesData: NotificationType[] = useSelector(getNotificationGroups)
    .filter((it: NotificationGroupData) => it.notifications && it.notifications.length > 0)
    .map((it: NotificationGroupData) => {
      return {
        label: it.appKey,
        name: it.settings.name,
      };
    });

  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const [severities, setSeverities] = useState<SelectableItem[]>([]);
  const [showMoreSeverities, setShowMoreSeverities] = useState(false);

  const [types, setTypes] = useState<SelectableItem[]>([]);
  const [showMoreTypes, setShowMoreTypes] = useState(false);

  const [dateFrom, setDateFrom] = useState<Date | undefined>(undefined);
  const [dateTo, setDateTo] = useState<Date | undefined>(undefined);

  const buildAllOption = () => {
    return {
      label: ALL_OPTION,
      name: I18n.t('notifications.all'),
      selected: true,
    };
  };

  useEffect(() => {
    setSearchText(notificationFilter?.searchText);
    setDateFrom(notificationFilter?.from);
    setDateTo(notificationFilter?.to);

    setSeverities(
      [
        buildAllOption(),
        ...severitiesData.map(it => {
          return {label: it.label, name: it.name, selected: false};
        }),
      ].map(it => {
        if (notificationFilter) {
          // consider filter from props
          it.selected = notificationFilter?.severityLabels.includes(it.label) || false;
        }
        return it;
      }),
    );

    setTypes(
      [
        buildAllOption(),
        ...typesData.map(it => {
          return {label: it.label, name: it.name, selected: false};
        }),
      ].map(it => {
        if (notificationFilter) {
          // consider filter from props
          it.selected = notificationFilter?.typesLabels.includes(it.label) || false;
        }
        return it;
      }),
    );
  }, []);

  useEffect(() => {
    if (props.enumPickerData && props.enumPickerData.data) {
      if (props.enumPickerData.entityType == FilteredEnumType.SEVERITY) {
        console.log('severities data, enumPickerData ' + JSON.stringify(props.enumPickerData));
        setSeverities([...props.enumPickerData.data]);
      } else if (props.enumPickerData.entityType == FilteredEnumType.TYPE) {
        setTypes([...props.enumPickerData.data]);
      }
    }
  }, []);

  const setSelected = (input: SetValue) => {
    const dataToUpdate = input.dataToUpdate;
    const item = dataToUpdate.find(it => it.label === input.label);
    if (item?.label !== ALL_OPTION) {
      const allItem = dataToUpdate.find(it => it.label === ALL_OPTION);
      if (allItem?.selected) allItem.selected = false;
    } else {
      /// reset all choices to false, if ALL, then only the one choice must be selected
      dataToUpdate.filter(it => it.label !== ALL_OPTION).forEach(it => (it.selected = false));
      item.selected = true;
    }

    if (item && item.label !== ALL_OPTION) {
      item.selected = !item.selected;
    }

    if (
      dataToUpdate.filter(it => it.label !== ALL_OPTION).length ===
      dataToUpdate.filter(it => it.label !== ALL_OPTION && it.selected).length
    ) {
      /// If all values are picked, but not the all value, select the all value
      dataToUpdate.filter(it => it.label !== ALL_OPTION).forEach(it => (it.selected = false));
      dataToUpdate.filter(it => it.label === ALL_OPTION).forEach(it => (it.selected = true));
    }

    if (dataToUpdate.filter(it => !it.selected).length === dataToUpdate.length) {
      /// if all is unselected, select all by default
      dataToUpdate.filter(it => it.label === ALL_OPTION).forEach(it => (it.selected = true));
    }
    input.setterFunction([...dataToUpdate]);
  };

  const checkNumberOfLinesOnSeverities = useCallback(e => {
    setShowMoreSeverities(e.nativeEvent.lines.length > numberOfLines);
  }, []);

  const checkNumberOfLinesOnTypes = useCallback(e => {
    setShowMoreTypes(e.nativeEvent.lines.length > numberOfLines);
  }, []);

  const updateSelected = (type: SelectableItem) => {
    setSelected({
      label: type.label,
      dataToUpdate: types,
      setterFunction: setTypes,
    });
  };

  const updateSelectedSeverity = (severity: SelectableItem) => {
    setSelected({
      label: severity.label,
      dataToUpdate: severities,
      setterFunction: setSeverities,
    });
  };

  const buildFilter = (): NotificationFilter | undefined => {
    const filter: NotificationFilter = {
      searchText: searchText,
      severityLabels: severities.filter(it => it.selected).map(it => it.label),
      typesLabels: types.filter(it => it.selected).map(it => it.label),
      from: dateFrom,
      to: dateTo,
    };

    const isOneFilterFieldPresent =
      filter.searchText ||
      filter.typesLabels.filter(it => it !== ALL_OPTION).length > 0 ||
      filter.severityLabels.filter(it => it !== ALL_OPTION).length > 0 ||
      filter.from ||
      filter.to;
    console.log(isOneFilterFieldPresent);
    return isOneFilterFieldPresent ? filter : undefined;
  };

  const setDefaultSettings = () => {
    setSearchText(undefined);
    setDateFrom(undefined);
    setDateTo(undefined);
    setSeverities([
      ...severities.map(it => {
        it.selected = it.label === ALL_OPTION;
        return it;
      }),
    ]);
    setTypes([
      ...types.map(it => {
        it.selected = it.label === ALL_OPTION;
        return it;
      }),
    ]);
  };

  return (
    <ScrollView contentContainerStyle={{flexGrow: 1}}>
      <View style={styles.container}>
        <TouchableOpacity style={styles.clearButton} onPress={setDefaultSettings}>
          <Text style={styles.clearButtonTitle}>{i18n.t('notifications.clear_filter')}</Text>
        </TouchableOpacity>
        <View style={[styles.box, {marginTop: 30}]}>
          <Text style={styles.boxTitle}>{i18n.t('notifications.search')}</Text>
          <View style={{flexDirection: 'row', width: '100%'}}>
            <FontAwesome5Icon name={'search'} size={20} style={{alignSelf: 'center'}} />
            <TextInput
              placeholder={i18n.t('notifications.search_placeholder')}
              style={styles.searchInput}
              onChangeText={value => setSearchText(value)}
              value={searchText}></TextInput>
            {searchText !== undefined && searchText !== '' && (
              <TouchableOpacity style={styles.clearIcon} onPress={() => setSearchText(undefined)}>
                <Icon name="close" size={25} color={dhlRed} />
              </TouchableOpacity>
            )}
          </View>
          <View style={styles.line}></View>
        </View>
        <View style={styles.box}>
          <Text style={[styles.boxTitle, {marginBottom: 10}]}>{i18n.t('notifications.priority')}</Text>
          <View style={styles.enumBox}>
            <Text numberOfLines={numberOfLines} onTextLayout={checkNumberOfLinesOnSeverities}>
              {severities.map(severity => {
                return (
                  <TouchableOpacity
                    key={severity.label + severity.name}
                    onPress={() => updateSelectedSeverity(severity)}
                    style={[styles.enumItem, {backgroundColor: severity.selected ? dhlBlue : customGrey}]}>
                    <Text style={[styles.enumTitle, {color: severity.selected ? dhlWhiteBasic : dhlBlack}]}>
                      {severity.name}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </Text>
          </View>
          {showMoreSeverities && (
            <TouchableOpacity
              style={styles.seeAllButton}
              onPress={() =>
                dispatch(
                  navigationPushAction('notificationFilterEnumPickerScreen', {
                    data: severities,
                    entityType: FilteredEnumType.SEVERITY,
                  }),
                )
              }>
              <Text style={styles.seeAllText}>{i18n.t('notifications.see_all')}</Text>
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.box}>
          <Text style={[styles.boxTitle, {marginBottom: 10}]}>{i18n.t('notifications.types')}</Text>
          <View style={styles.enumBox}>
            <Text numberOfLines={numberOfLines} onTextLayout={checkNumberOfLinesOnTypes}>
              {types.map(type => {
                return (
                  <TouchableOpacity
                    key={type.label + type.name}
                    onPress={() => updateSelected(type)}
                    style={[styles.enumItem, {backgroundColor: type.selected ? dhlBlue : customGrey}]}>
                    <Text style={[styles.enumTitle, {color: type.selected ? dhlWhiteBasic : dhlBlack}]}>
                      {type.name}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </Text>
          </View>
          {showMoreTypes && (
            <TouchableOpacity
              style={styles.seeAllButton}
              onPress={() =>
                dispatch(
                  navigationPushAction('notificationFilterEnumPickerScreen', {
                    data: types,
                    entityType: FilteredEnumType.TYPE,
                  }),
                )
              }>
              <Text style={styles.seeAllText}>{i18n.t('notifications.see_all')}</Text>
            </TouchableOpacity>
          )}
        </View>
        <View style={[styles.box, {marginTop: 25}]}>
          <Text style={[styles.boxTitle, {marginBottom: 10}]}>{i18n.t('notifications.date_and_time_range')}</Text>
          <View style={{flexDirection: 'row'}}>
            <DateTime
              title={i18n.t('notifications.from')}
              setDate={value => setDateFrom(value)}
              date={dateFrom}
              noTimeText={i18n.t('notifications.select')}
              noDateText={i18n.t('notifications.select')}
            />
            {dateFrom && (
              <TouchableOpacity style={styles.clearDateButton} onPress={() => setDateFrom(undefined)}>
                <Icon name="close" size={25} color={dhlRed} />
              </TouchableOpacity>
            )}
          </View>
          {dateFrom && dateTo && dateTo < dateFrom && (
            <Text style={{color: dhlRed}}>{i18n.t('notifications.invalid_dates')}</Text>
          )}
          <View style={{flexDirection: 'row'}}>
            <DateTime
              title={i18n.t('notifications.to')}
              setDate={value => setDateTo(value)}
              date={dateTo}
              noTimeText={i18n.t('notifications.select')}
              noDateText={i18n.t('notifications.select')}
            />
            {dateTo && (
              <TouchableOpacity style={styles.clearDateButton} onPress={() => setDateTo(undefined)}>
                <Icon name="close" size={25} color={dhlRed} />
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
      <TouchableOpacity
        disabled={dateFrom && dateTo && dateTo < dateFrom}
        style={styles.applyButton}
        onPress={() => {
          const notificationFilter = buildFilter();
          dispatch(setNotificationFilter(notificationFilter));
          dispatch(navigationPushAction('notificationsScreen'));
        }}>
        <Text style={styles.applyText}>{i18n.t('notifications.apply')}</Text>
      </TouchableOpacity>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
    alignItems: 'center',
    paddingBottom: 50,
  },
  clearButton: {position: 'absolute', top: 0, right: 0, padding: 20, paddingTop: 10},
  clearButtonTitle: {color: dhlRedButton, fontWeight: 'bold'},
  box: {alignItems: 'flex-start', width: '95%'},
  boxTitle: {fontSize: 15, fontWeight: 'bold'},
  searchInput: {paddingLeft: 10, width: '80%', paddingBottom: 5, paddingTop: 5},
  clearIcon: {
    justifyContent: 'center',
    padding: 10,
    paddingRight: 20,
  },
  clearDateButton: {justifyContent: 'center', paddingLeft: 10, paddingTop: 5},
  enumBox: {
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
  },
  enumItem: {
    margin: 1,
    height: 40,
    paddingTop: 10,
    paddingBottom: 10,
    padding: 25,
    borderRadius: 15,
  },
  enumTitle: {
    fontWeight: 'bold',
  },
  seeAllButton: {alignSelf: 'flex-end', padding: 10},
  seeAllText: {
    color: dhlBlue,
    textDecorationLine: 'underline',
  },
  applyButton: {
    position: 'absolute',
    backgroundColor: dhlStatusGreen,
    paddingTop: 10,
    paddingBottom: 10,
    padding: 20,
    borderRadius: 5,
    bottom: 15,
    right: 15,
  },
  applyText: {color: dhlWhiteBasic, fontSize: 18},
  line: {width: '100%', height: 1, backgroundColor: dhlGrey, marginBottom: 20},
});

export default NotificationFilterScreen;
