import React, {Component} from 'react';
import {Dimensions, Linking, ScrollView, View} from 'react-native';
import AppWidget from '../component/AppWidget';
import {connect} from 'react-redux';
import {styles} from '../component/commonStyles';
import GestureRecognizer from 'react-native-swipe-gestures';
import I18n from 'features/I18n';
import {KIOSK_FOLDER} from '../config';
import {setDoPingTestAction, setWasChangePinModalShown} from '../flows/app';
import {logi} from 'features/logging';
import {setShowNotification} from '../flows/notification';
import {formatDateTime} from '../utils/DateTime';
import ActionModal from '../component/ActionModal';
import {navigationPushAction} from '@smartops/smartops-shared';
import {isWeb} from 'features/platformSpecific';
import {ScaledSheet} from 'react-native-size-matters';
import {CounterWidget} from '../component/CounterWidget';
import {reloadApplicationsNotifications} from '../flows/applicationNotification';

const SwipeWrapper = isWeb ? View : GestureRecognizer;
const appsWithCounter = ['indApr', 'inc_mng', '306090'];

class MainPage extends Component {
  constructor(props) {
    super(props);
    window.allowRedirect = false;
    this.state = {
      showUmChangeNotification: false,
      showPwdReset: !!props.showPasswordWillExpireNotification,
    };
  }

  render() {
    const config = {
      velocityThreshold: 0.7, //Velocity that has to be breached in order for swipe to be triggered
      directionalOffsetThreshold: 480, //Absolute offset that shouldn't be breached for swipe to be triggered
      gestureIsClickThreshold: 50, //Absolute distance that should be breached for the gesture to not be considered a click
    };
    const {
      emergencyMode,
      folder,
      notificationFields,
      is24HourFormat,
      androidLocale,
      isPinAuth,
      daysToPasswordExpiration,
      navigationPushAction,
      setShowNotification,
      wasChangePinModalShown,
      setWasChangePinModalShown,
    } = this.props;
    const {
      umChangeNotificationMessageFromDate,
      umChangeNotificationMessageToDate,
      umChangeDisplayNotification,
      notificationMessageFromDate,
      notificationMessageToDate,
      showNotification,
    } = notificationFields;
    const {showUmChangeNotification, showPwdReset} = this.state;
    const fromDateStr = notificationMessageFromDate;
    const toDateStr = notificationMessageToDate;
    const fromDate = showNotification ? formatDateTime(new Date(fromDateStr), is24HourFormat, androidLocale) : '';
    const toDate = showNotification ? formatDateTime(new Date(toDateStr), is24HourFormat, androidLocale) : '';
    const {scale, width, height} = Dimensions.get('window');
    let widthStyle = {};
    if (width < 400) {
      // Handle small devices
      logi(`>>> width < 400 scale: ${scale} width: ${width} height: ${height}`);
      widthStyle = ScaledSheet.create({
        maxW: {
          minWidth: '130@ms0.2',
          maxWidth: '130@ms0.2',
        },
      });
    }
    const umFromDateStr = umChangeNotificationMessageFromDate;
    const umToDateStr = umChangeNotificationMessageToDate;
    const umFromDate = umChangeDisplayNotification
      ? formatDateTime(new Date(umFromDateStr), is24HourFormat, androidLocale)
      : '';
    const umToDate = umChangeDisplayNotification
      ? formatDateTime(new Date(umToDateStr), is24HourFormat, androidLocale)
      : '';
    return (
      <SwipeWrapper style={styles.container} onSwipeDown={() => navigationPushAction('menuScreen')} config={config}>
        <View>
          <ScrollView style={{height: '100%'}}>
            <View style={styles.appContainer}>
              {emergencyMode === false && !folder && this.renderOnlineRootApplications()}
              {(emergencyMode === true || folder === KIOSK_FOLDER) && this.renderKioskApplications()}
            </View>
          </ScrollView>
        </View>
        <ActionModal
          titleKey={'notification.title'}
          visible={showNotification}
          container={style.notificationModal}
          message={I18n.t('notification.message', {from: fromDate, to: toDate})}
          showCancelButton={true}
          cancelButtonKey={'common.ok'}
          setVisible={() => setShowNotification(false)}
        />
        <ActionModal
          titleKey={'notification.umChangeTitle'}
          visible={showUmChangeNotification}
          container={style.umNotificationModal}
          message={I18n.t('notification.umChangeMessage', {
            from: umFromDate,
            to: umToDate,
          })}
          showCancelButton={true}
          cancelButtonKey={'common.ok'}
          setVisible={() => this.setState({showUmChangeNotification: false})}
        />
        {daysToPasswordExpiration != null && !wasChangePinModalShown && (
          <ActionModal
            titleKey={I18n.t(isPinAuth ? 'pinReset.notificationTitle' : 'pwdReset.notificationTitle')}
            visible={showPwdReset}
            container={style.pinNotificationModal}
            message={I18n.t(isPinAuth ? 'pinReset.notificationMessage' : 'pwdReset.notificationMessage', {
              days: daysToPasswordExpiration,
            })}
            showCancelButton={true}
            cancelButtonKey={'pinReset.later'}
            continueButtonKey={isPinAuth ? 'pinReset.title' : 'pwdReset.title'}
            showContinueButton={true}
            buttonStyle={widthStyle.maxW}
            onCancel={() => {
              setWasChangePinModalShown(true);
            }}
            onContinue={async () => {
              setWasChangePinModalShown(true);
              if (umChangeDisplayNotification) {
                this.setState({showUmChangeNotification: true});
              } else {
                navigationPushAction('setPassScreen', {oldPassword: null});
              }
            }}
            setVisible={bool => this.setState({showPwdReset: bool})}
          />
        )}
      </SwipeWrapper>
    );
  }

  componentDidMount() {
    this.props.reloadApplicationsNotifications();
    Linking.getInitialURL()
      .then(url => {
        if (url) {
          //logd(`Initial url is: ${url}`);
        }
      })
      .catch(err => console.error('An error occurred', err));
  }

  isAllowedToShowBadge(appKey) {
    const showBadge = this.props.settings ? this.props.settings[appKey + '.badge_visibility'] : undefined;
    return showBadge === 'true';
  }

  renderOnlineRootApplications = () => {
    const {appsDetails, favoriteAppsKeys, availableWebAppKeys} = this.props;
    const appsToRender = appsDetails
      .filter(app => favoriteAppsKeys.includes(app.key))
      .filter(app => (isWeb ? availableWebAppKeys.includes(app.key.toLowerCase()) : true));
    let counterNum = 0;
    for (const app of appsToRender) {
      if (appsWithCounter.includes(app.key)) {
        counterNum++;
      }
    }
    return appsToRender.map(app => {
      const isCounterWidget = appsWithCounter.includes(app.key);
      const WidgetComponent = isCounterWidget && this.isAllowedToShowBadge(app.key) ? CounterWidget : AppWidget;
      // indApr must have new key on every main page entry to re-fire load of requests count
      const key = `${app.key}`;
      return <WidgetComponent key={key} app={app} />;
    });
  };

  renderKioskApplications = () => {
    const {kioskApps, emergencyMode, appsDetails} = this.props;
    const onlineAppsKeys = appsDetails.map(app => app.key);
    const emergencyApps = kioskApps.filter(app => app.isEmergencyApp);
    emergencyApps.sort((a, b) => a.order - b.order);
    const allApps = emergencyMode ? emergencyApps : emergencyApps.filter(app => !onlineAppsKeys.includes(app.key));
    const normalApps = kioskApps.filter(app => !app.isEmergencyApp);
    normalApps.sort((a, b) => a.order - b.order);
    normalApps.forEach(app => allApps.push(app));
    return allApps.map(app => {
      return <AppWidget key={app.key} app={app} />;
    });
  };
}

function mapStateToProps(state) {
  const isPinAuth = state.auth.user.authType === 'authenticationType.pinAuth';
  return {
    favoriteAppsKeys: state.apps.favoriteAppsKeys || [],
    appsDetails: state.apps.appsDetails || [],
    isTablet: state.app.isTablet,
    kioskApps: state.kiosk.kioskApps,
    emergencyMode: state.app.emergencyMode,
    folder: state.app.folder,
    notificationFields: state.notification,
    is24HourFormat: state.app.is24HourFormat,
    androidLocale: state.app.androidLocale,
    availableWebAppKeys: state.app.availableWebAppKeys,
    isPinAuth: isPinAuth,
    doPingTest: state.app.doPingTest,
    settings: state.settings.settings,
    wasChangePinModalShown: state.app.wasChangePinModalShown,
  };
}

const style = ScaledSheet.create({
  notificationModal: {
    minHeight: '280@ms0.2',
  },
  umNotificationModal: {
    minHeight: '300@ms0.2',
  },
  pinNotificationModal: {
    minHeight: '220@ms0.2',
    minWidth: '320@ms0.2',
    maxWidth: '410@ms0.2',
    alignSelf: 'center',
  },
});

export default connect(mapStateToProps, {
  setShowNotification,
  navigationPushAction,
  setDoPingTestAction,
  reloadApplicationsNotifications,
  setWasChangePinModalShown,
})(MainPage);
