import React, {Component} from 'react';
import {Text, View} from 'react-native';
import {connect} from 'react-redux';
import {BetterCamera} from 'component/BetterCamera';
import {storeScannedValue} from 'flows/scanner';
import {SecondaryButton} from 'component/SecondaryButton';
import I18n from 'features/I18n';
import {logd, logi} from 'features/logging';
import {
  dhlBasicOrange,
  navigationPopToAction,
  getCurrentScreenSelector as getCurrentScreen,
} from '@smartops/smartops-shared';

class ScannerScreen extends Component {
  constructor(props) {
    super(props);
  }

  onBarCodeRead = event => {
    if (this.props.currentScreen === 'pageScanner') {
      logi('Scan ok: ', event);
      this.props.storeScannedValue(event.data);
      this.props.navigationPopToAction('pageLogin');
    }
  };

  close = () => {
    logi('Cancelled');
    this.props.navigationPopToAction('pageLogin');
  };

  renderCamera() {
    if (this.props.scanning) {
      return (
        <BetterCamera
          style={style.scannerCamera}
          onBarCodeRead={this.onBarCodeRead}
          barcodeScannerEnabled={true}
          captureAudio={false}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <View style={{flex: 1, backgroundColor: dhlBasicOrange}}>
        {this.renderCamera()}
        <View style={style.scannerScreenRectangle} pointerEvents="none" />
        <View style={style.scannerScreenHeader}>
          <Text style={style.scannerScreenTitle} pointerEvents="none">
            {I18n.t('login.scanUserName')}
          </Text>
        </View>
        <SecondaryButton
          title={I18n.t('common.close')}
          icon={{name: 'close', type: 'font-awesome'}}
          onPress={this.close}
          containerViewStyle={style.scannerScreenCloseButton}
        />
      </View>
    );
  }
}

const style = {
  scannerScreenRectangle: {
    position: 'absolute',
    left: 40,
    right: 40,
    top: 80,
    bottom: 100,
    borderWidth: 1,
    borderColor: '#fff',
    opacity: 0.5,
  },
  scannerScreenHeader: {
    position: 'absolute',
    left: 0,
    right: 0,
    backgroundColor: '#000',
    height: 60,
  },
  scannerScreenTitle: {
    color: '#fff',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 26,
    top: 10,
  },
  scannerScreenCloseButton: {
    position: 'absolute',
    bottom: '4%',
    alignSelf: 'center',
    flexDirection: 'row',
  },
  scannerCamera: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

const mapStateToProps = state => {
  return {
    scanning: state.scanner.scanning,
    currentScreen: getCurrentScreen(state),
  };
};

export default connect(mapStateToProps, {storeScannedValue, navigationPopToAction, getCurrentScreen})(ScannerScreen);
