import {useDispatch, useSelector} from 'react-redux';
import {
  BottomBar,
  getCurrentScreenSelector,
  navigationPopToAction,
  navigationPushAction,
} from '@smartops/smartops-shared';
import I18n from '../features/I18n';
import {getEmergencyModeSelector, getFolderSelector, getIsAppOnline, storeFolderAction} from '../flows/app';
import {
  areAllNotificationsGroupFromPhase1Inactive,
  areNotificationsLoading,
  getAreNotificationFromPhase2VisibleToEveryone,
  getCountOfApplicationNotifications,
  loadApplicationsNotificationsAction,
  resetIsNotificationsListOpen,
} from '../flows/applicationNotification';
import {useEffect} from 'react';
import {View} from 'react-native';
import {isAllowedToShowBottomBarMenuSelector} from '../flows/auth';

const MENU_SCREENS = [
  'menuScreen',
  'userGroupManagementScreen',
  'selectUserGroupScreen',
  'accessDetailsScreen',
  'pageChoose',
  'pageSettings',
  'fileTransport',
  'wmsSettingsScreen',
  'aboutScreen',
  'logScreen',
];

const ConfiguredBottomBar = () => {
  const isOnline: boolean | undefined | null = useSelector(getIsAppOnline);
  const currentScreen = useSelector(getCurrentScreenSelector);
  const countOfApplicationNotifications = useSelector(getCountOfApplicationNotifications);
  const areApplicationNotificationsLoading = useSelector(areNotificationsLoading);
  const areAllNotificationGroupsFromPhase1Inactive = useSelector(areAllNotificationsGroupFromPhase1Inactive);
  const areNotificationsFromPhase2VisibleToEveryone = useSelector(getAreNotificationFromPhase2VisibleToEveryone);
  const emergencyMode = useSelector(getEmergencyModeSelector);
  const folder = useSelector(getFolderSelector);
  const isAllowedToShow = useSelector(isAllowedToShowBottomBarMenuSelector);
  const dispatch = useDispatch();

  const menuClick = () => {
    currentScreen !== 'pageLogin' && dispatch(navigationPushAction('menuScreen'));
  };

  const homeClick = () => {
    if (emergencyMode) {
      dispatch(navigationPushAction('pageLogin', {attemptNumber: 0}));
    } else if (folder) {
      dispatch(storeFolderAction(null));
    } else {
      dispatch(loadApplicationsNotificationsAction());
      dispatch(navigationPopToAction('pageMain'));
    }
  };

  useEffect(() => {
    if (true === isOnline) {
      dispatch(loadApplicationsNotificationsAction());
    }
  }, [isOnline]);

  const notificationsClick = () => {
    dispatch(resetIsNotificationsListOpen());
    if (currentScreen === 'notificationsScreen') {
      dispatch(loadApplicationsNotificationsAction());
    } else {
      dispatch(navigationPushAction('notificationsScreen'));
    }
  };

  return isAllowedToShow || (emergencyMode && 'selectUserGroupScreen' !== currentScreen) ? (
    <BottomBar
      homeIconActive={currentScreen === 'pageMain'}
      notificationIconActive={
        currentScreen &&
        [
          'notificationsScreen',
          'notificationDetailScreen',
          'notificationFilterScreen',
          'notificationFilterEnumPickerScreen',
        ].includes(currentScreen)
      }
      menuIconActive={MENU_SCREENS.includes(currentScreen)}
      homeText={I18n.t('common.home')}
      menuText={I18n.t('menu.menuTitle')}
      notificationsText={I18n.t('notifications.button')}
      onMenuClick={menuClick}
      onHomeClick={homeClick}
      notificationsCount={countOfApplicationNotifications}
      notificationsLoading={areApplicationNotificationsLoading}
      onNotificationsClick={notificationsClick}
      showNotificationsCount={true}
      showNotificationIcon={
        !emergencyMode && (!areAllNotificationGroupsFromPhase1Inactive || areNotificationsFromPhase2VisibleToEveryone)
      }
    />
  ) : (
    <View></View>
  );
};

export default ConfiguredBottomBar;
