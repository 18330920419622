import React, {Component} from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Keyboard,
  NativeModules,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {connect} from 'react-redux';
import {splashStyles} from './SplashScreen';
import I18n from '../features/I18n';
import {startScanning} from '../flows/scanner';
import {Icon} from 'react-native-elements';
import LinearGradient from 'react-native-linear-gradient';
import {dhlBasicOrange, dhlBlack, dhlLightOrange, dhlRedButton, dhlWhiteBasic, styles} from '../component/commonStyles';
import DeviceInfo from 'react-native-device-info';
import {ScaledSheet} from 'react-native-size-matters';
import {
  ActionButton,
  dhlBlue,
  doubleClickSafe,
  getCurrentScreenSelector as getCurrentScreen,
  navigationPushAction,
} from '@smartops/smartops-shared';
import ActionModal from '../component/ActionModal';
import {loginAttemptAction} from '../flows/login';
import {enterEmergencyAction, setShowSelectGroupModalAction, storeShowEmergencyPromptAction} from '../flows/app';
import {persistShowNumpad, storeUserGroupsAction, userGroupContinueLoginAction} from '../flows/auth';
import SelectUserGroupModal from '../component/SelectUserGroupModal';
import {isQa, isWeb, showToast} from 'features/platformSpecific';
import {appVersion} from '../configs/version';
import {logi} from '../features/logging';

const SafeTouchableOpacity = doubleClickSafe(TouchableOpacity);
// eslint-disable-next-line no-unused-vars
const authTypes = ['authenticationType.pinAuth', 'authenticationType.pwdAuth', 'authenticationType.ldapAuth'];

const ScannerButton = ({onPressHandler}) => {
  return (
    <View style={style.iconView}>
      <SafeTouchableOpacity onPress={onPressHandler}>
        <Icon name={'barcode-scan'} type={'material-community'} iconStyle={style.iconStyle} />
      </SafeTouchableOpacity>
    </View>
  );
};

const ChangeKeyboardButton = ({onPressHandler, showNumpad}) => {
  return (
    <View style={style.iconView}>
      <SafeTouchableOpacity onPress={onPressHandler} repeatedPressIgnoreTime={500}>
        <Icon
          name={showNumpad ? 'dialpad' : 'keyboard-o'}
          type={showNumpad ? 'material-community' : 'font-awesome'}
          iconStyle={style.iconStyle}
        />
      </SafeTouchableOpacity>
    </View>
  );
};

let {width, height} = Dimensions.get('screen');

class LoginScreen extends Component {
  constructor(props) {
    super(props);
    const isLoading = props.isLoading === true;
    this.state = {
      isLoading: isLoading,
      showKeyboard: false,
      login: isLoading ? props.login : '',
      password: isLoading ? '******' : '',
      error: props.error,
      focusedInput: undefined,
      attemptNumber: props.attemptNumber || 1,
      isPortrait: width < height,
      counter: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.login && this.props.scannedUser) {
      this.setState({login: this.props.scannedUser});
    }
    //logd(`Login page with prev attemptNumber ${prevProps.attemptNumber} and new attemptNumber ${this.props.attemptNumber}`);
    if (this.props.attemptNumber !== prevProps.attemptNumber) {
      this.setState({error: this.props.error, isLoading: false});
    }
    if (prevState.isLoading === true && this.state.isLoading === false && this.props.clearFields === true) {
      this.setState({login: '', password: '', attemptNumber: this.props.attemptNumber + 1});
    }
  }

  componentDidMount() {
    if (isWeb) {
      window.visualViewport.addEventListener('resize', e => this.increaseCounter());
    } else {
      Dimensions.addEventListener('change', ({window, screen}) => {
        const {width, height} = window;
        this.setState({
          isPortrait: width < height,
        });
      });
    }
  }

  onLoginChange = text => this.setState({login: text, error: null});
  onPasswordChange = text => this.setState({password: text, error: null});
  increaseCounter = () => this.setState({counter: this.state.counter + 1});

  onFocusCustom = () => {
    if (!this.state.showKeyboard) {
      if (!isWeb) {
        NativeModules.ParentNativeTools.hideKeyboard();
      }
      this.setState({showKeyboard: true, focusedInput: 'login'});
    } else {
      this.setState({focusedInput: 'login'});
    }
  };

  onLoginPress = async () => {
    const {login, password} = this.state;
    const {loginAttemptAction, attemptNumber = 1, showNumpad} = this.props;
    const credsPresent = login?.trim().length > 0 && password?.trim().length > 0;
    Keyboard.dismiss();
    if (credsPresent && showNumpad && (isNaN(password) || password?.length !== 6)) {
      this.setState({
        isLoading: false,
        error: I18n.t('login.pinLengthDigitsOnly'),
      });
    } else if (credsPresent) {
      this.setState({isLoading: true, error: null});
      loginAttemptAction(login.toLowerCase(), password, attemptNumber + 1);
    } else {
      //Show error
      this.setState({
        isLoading: false,
        error: I18n.t('login.mandatoryCredentials'),
      });
    }
  };

  renderError = () => {
    if (this.state.error) {
      return (
        <View>
          <Text style={style.errorText} accessibilityLabel="login_error">
            {this.state.error}
          </Text>
        </View>
      );
    }
  };

  render() {
    const {
      scannedUser: user,
      showEmergencyPrompt: showEmergency,
      userGroups,
      selectedUserGroup,
      showSelectGroupModal,
      storeUserGroupsAction,
      keyboardIsOpen,
      showNumpad,
      persistShowNumpad,
      enterEmergencyAction,
      kioskConfigAvailable,
      startScanning,
      navigationPushAction,
      currentScreen,
      setShowSelectGroupModalAction,
      userGroupContinueLoginAction,
      notificationFields,
      is24HourFormat,
      androidLocale,
    } = this.props;
    const {focusedInput, isLoading, login, password, attemptNumber, counter, isPortrait} = this.state;
    const {scale, width, height, fontScale} = Dimensions.get('window');
    let widthStyle = {};
    let emStyle = {};
    if (width < 400) {
      // Handle small devices
      logi(`>>> width < 400 scale: ${scale} width: ${width} height: ${height} fontScale: ${fontScale}`);
      widthStyle = ScaledSheet.create({
        maxW: {
          maxWidth: '200@ms0.2',
        },
      });
      emStyle = ScaledSheet.create({
        maxW: {
          minWidth: '130@ms0.2',
          maxWidth: '130@ms0.2',
        },
      });
    }
    return (
      <ScrollView
        keyboardShouldPersistTaps={'always'}
        contentContainerStyle={[
          {flexGrow: 1},
          {
            borderBottomColor: 'black',
            borderBottomWidth: 0,
          },
        ]}
        enabled
        style={{minHeight: height}}>
        <LinearGradient colors={[dhlBasicOrange, dhlLightOrange]} style={[styles.mainContainer, style.mainContainer]}>
          <ActionModal
            titleKey={'emergencyModeModal.title'}
            visible={showEmergency}
            messageKey={'emergencyModeModal.message'}
            buttonStyle={emStyle.maxW}
            setVisible={bool => {
              this.setState({showEmergency: bool, isLoading: false});
              this.props.storeShowEmergencyPromptAction(false);
            }}
            onContinue={() => {
              this.setState({isLoading: false});
              if (kioskConfigAvailable) {
                enterEmergencyAction();
              } else {
                showToast(I18n.t('errors.kioskConfigMissing'));
              }
            }}
          />
          <Text style={splashStyles.textStyle}>
            <Text style={{color: dhlRedButton}}>SMART</Text>OPERATIONS
          </Text>
          <View style={[style.rowView, widthStyle.maxW]}>
            <TextInput
              style={style.input}
              key={'username_input' + attemptNumber}
              editable={!isLoading}
              accessibilityLabel="login_input1"
              underlineColorAndroid="rgba(0,0,0,0)"
              autoCapitalize={'none'}
              onSubmitEditing={() => this.passwordInput.focus()}
              autoFocus={true}
              ref={input => (this.loginInput = input)}
              onFocus={() => this.onFocusCustom()}
              defaultValue={user || login}
              autoCorrect={false}
              keyboardType="email-address"
              returnKeyType="next"
              placeholder={I18n.t('login.placeholderUsername')}
              placeholderTextColor={dhlBlack}
              onChangeText={text => this.onLoginChange(text)}
              maxLength={113}
              blurOnSubmit={false}
            />
            {!isWeb && (
              <View style={style.col3}>
                <ScannerButton onPressHandler={() => startScanning()} />
              </View>
            )}
          </View>
          <View style={[style.rowView, widthStyle.maxW]} accessibilityRole={isWeb ? 'form' : null}>
            {showNumpad ? (
              <TextInput
                style={[style.input, {marginBottom: '1%'}]}
                editable={!isLoading}
                key={'num_key' + attemptNumber}
                accessibilityLabel="login_input2"
                underlineColorAndroid="rgba(0,0,0,0)"
                autoCorrect={false}
                autoCapitalize={'none'}
                returnKeyType="go"
                keyboardType={'numeric'}
                defaultValue={password}
                ref={input => (this.passwordInput = input)}
                onFocus={() => this.setState({focusedInput: 'password'})}
                onSubmitEditing={this.onLoginPress}
                placeholder={I18n.t('login.placeholderPassword')}
                placeholderTextColor={dhlBlack}
                onChangeText={this.onPasswordChange}
                multiline={false}
                maxLength={6}
                secureTextEntry={true}
              />
            ) : (
              <TextInput
                style={[style.input, {marginBottom: '1%'}]}
                editable={!isLoading}
                key={'text_key' + attemptNumber}
                accessibilityLabel="login_input2"
                underlineColorAndroid="rgba(0,0,0,0)"
                autoCorrect={false}
                autoCapitalize={'none'}
                returnKeyType="go"
                keyboardType={'default'}
                defaultValue={password}
                ref={input => (this.passwordInput = input)}
                onFocus={() => this.setState({focusedInput: 'password'})}
                onSubmitEditing={this.onLoginPress}
                placeholder={I18n.t('login.enterPassword')}
                placeholderTextColor={dhlBlack}
                onChangeText={this.onPasswordChange}
                multiline={false}
                maxLength={256}
                secureTextEntry={true}
              />
            )}
            <View style={style.col3}>
              <ChangeKeyboardButton
                onPressHandler={() => {
                  if (keyboardIsOpen && !!focusedInput) {
                    // eslint-disable-next-line @typescript-eslint/no-this-alias
                    const that = this;
                    setTimeout(() => (focusedInput === 'login' ? that.loginInput : that.passwordInput).focus(), 450);
                  }
                  persistShowNumpad(!showNumpad);
                }}
                showNumpad={showNumpad}
              />
            </View>
          </View>
          {!isLoading ? (
            <ActionButton
              type={'Primary'}
              onPress={this.onLoginPress}
              accessibilityLabel="login_button"
              title={I18n.t('login.login')}
              textStyle={{color: dhlWhiteBasic, fontWeight: '600'}}
              containerViewStyle={[style.loginButtonStyle, widthStyle.maxW]}
            />
          ) : (
            <ActivityIndicator size={'small'} />
          )}

          <View style={style.errorContainer}>{this.renderError()}</View>
          {keyboardIsOpen && <View style={{height: 150}} />}
          <View style={style.footer}>
            <Text accessibilityLabel={'login_version'}>
              v{isWeb ? (isQa() ? `${appVersion}-qa` : appVersion) : DeviceInfo.getVersion()}
            </Text>
            <Text
              style={style.linkStyle}
              accessibilityLabel={'login_about'}
              onPress={() => currentScreen !== 'aboutScreen' && navigationPushAction('aboutScreen', {back: true})}>
              {I18n.t('login.about')}
            </Text>
          </View>
          <SelectUserGroupModal
            notificationFields={notificationFields}
            is24HourFormat={is24HourFormat}
            androidLocale={androidLocale}
            visible={showSelectGroupModal}
            setVisible={bool => setShowSelectGroupModalAction(bool)}
            selectedGroup={userGroups && userGroups.find(ug => ug.id === selectedUserGroup)?.description}
            defaultGroupId={userGroups && userGroups.find(ug => ug.isDefault === true)?.id}
            selectedGroupId={selectedUserGroup}
            storeDefaultGroupAction={storeUserGroupsAction}
            navigationPushAction={navigationPushAction}
            userGroups={userGroups}
            onContinueOrCancel={bool => userGroupContinueLoginAction(bool)}
          />
        </LinearGradient>
      </ScrollView>
    );
  }
}

export const style = ScaledSheet.create({
  input: {
    height: '40@ms0.2',
    paddingLeft: '4@ms0.2',
    width: '100%',
    backgroundColor: dhlWhiteBasic,
    color: dhlBlack,
    borderRadius: 4,
    fontSize: '14@ms0.2',
    fontWeight: 'normal',
  },
  iconView: {
    width: '64@ms0.2',
    marginLeft: '4@ms0.2',
    marginTop: '-2@ms0.2',
  },
  iconStyle: {
    fontSize: '40@ms0.2',
  },
  col3: {
    width: 0,
  },
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  errorContainer: {},
  loginButtonStyle: {
    width: '100%',
    maxWidth: '260@ms0.2',
  },
  rowView: {
    flexDirection: 'row',
    marginBottom: '1%',
    alignItems: 'center',
    width: '100%',
    maxWidth: '260@ms0.2',
  },
  errorText: {
    fontSize: '17@ms0.2',
    color: dhlRedButton,
    fontWeight: 'bold',
    alignSelf: 'stretch',
    alignItems: 'center',
    textAlignVertical: 'center',
    textAlign: 'center',
  },
  footer: {
    width: '94%',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  linkStyle: {
    textDecorationLine: 'underline',
    color: dhlBlue,
  },
});

function mapStateToProps(state) {
  return {
    notificationFields: state.notification,
    is24HourFormat: state.app.is24HourFormat,
    androidLocale: state.app.androidLocale,
    scannedUser: state.scanner.value,
    showEmergencyPrompt: state.app.showEmergencyPrompt,
    kioskConfigAvailable: state.kiosk.kioskConfigAvailable,
    showSelectGroupModal: state.app.showSelectGroupModal,
    userGroups: state.auth.userGroups,
    selectedUserGroup: state.auth.selectedUserGroup,
    keyboardIsOpen: state.app.keyboardIsOpen,
    showNumpad: state.auth.showNumpad,
    login: state.auth.user.login,
    currentScreen: getCurrentScreen(state),
  };
}

export default connect(mapStateToProps, {
  loginAttemptAction,
  startScanning,
  enterEmergencyAction,
  storeShowEmergencyPromptAction,
  setShowSelectGroupModalAction,
  userGroupContinueLoginAction,
  storeUserGroupsAction,
  persistShowNumpad,
  navigationPushAction,
  getCurrentScreen,
})(LoginScreen);
