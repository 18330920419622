import React from 'react';
import I18n from 'features/I18n';
import {ScaledSheet} from 'react-native-size-matters';
import {connect} from 'react-redux';
import {ScrollView, Text, useWindowDimensions, View} from 'react-native';
import {dhlBlack, styles} from '../component/commonStyles';
import {ActionButton} from '@smartops/smartops-shared';
import {setPrivacyNotificationResult} from '../flows/auth';
import {getFlex} from 'features/platformSpecific';

const PrivacyNotificationScreen = props => {
  const {setPrivacyNotificationResult} = props;
  const {width, height} = useWindowDimensions();
  let widthStyle = {};
  if (width > 400) {
    // Handle bigger screens
    widthStyle = ScaledSheet.create({
      mw: {
        width: null,
        minWidth: '350@ms0.2',
        maxWidth: '350@ms0.2',
      },
    });
  }
  return (
    <View style={[styles.container, style.container, {minHeight: height - 10}]}>
      <View style={{...getFlex(0.2), alignItems: 'center', justifyContent: 'center'}}>
        <Text style={style.title}>{I18n.t('privacyNotification.title')}</Text>
      </View>
      <ScrollView style={{flex: 2}} contentContainerStyle={style.textContainer}>
        <Text style={style.text}>{I18n.t('privacyNotification.mainText')}</Text>
      </ScrollView>
      <View style={[style.buttonsRow, widthStyle.mw]}>
        <ActionButton
          onPress={() => setPrivacyNotificationResult(false)}
          title={I18n.t('privacyNotification.decline')}
          accessibilityLabel={'decline'}
          type={'Primary'}
        />
        <ActionButton
          onPress={() => setPrivacyNotificationResult(true)}
          title={I18n.t('privacyNotification.accept')}
          accessibilityLabel={'accept'}
          type={'Tertiary'}
        />
      </View>
    </View>
  );
};

const style = ScaledSheet.create({
  container: {
    padding: '10@ms0.2',
    justifyContent: 'space-around',
    paddingTop: '20@ms0.2',
    alignSelf: 'center',
    alignItems: 'center',
  },
  textContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '600@ms0.2',
  },
  buttonsRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    maxHeight: '120@ms0.2',
    flex: 0.7,
    alignItems: 'center',
    width: '100%',
  },
  title: {
    color: dhlBlack,
    fontSize: '24@ms0.2',
    fontWeight: 'bold',
  },
  text: {
    textAlign: 'left',
    color: dhlBlack,
    fontSize: '18@ms0.2',
  },
});

export default connect(null, {setPrivacyNotificationResult})(PrivacyNotificationScreen);
