import React, {useState} from 'react';
import {Text, TouchableOpacity, useWindowDimensions, View} from 'react-native';
import Modal from 'react-native-modal';
import I18n from 'features/I18n';
import {ActionButton, doubleClickSafe} from '@smartops/smartops-shared';
import {ScaledSheet} from 'react-native-size-matters';
import {dhlBasicOrange, dhlBlack, dhlWhiteBasic} from './commonStyles';
import {Icon} from 'react-native-elements';
import {isDeviceOnline, isWeb, showToast} from 'features/platformSpecific';
import CheckBox from './CheckBox';
import Metrics from 'features/metrics';
import {formatDateTime} from '../utils/DateTime';

const SafeTouchableOpacity = doubleClickSafe(TouchableOpacity);

const SelectUserGroupModal = props => {
  const [makeDefault, setMakeDefault] = useState(false);
  const {width} = useWindowDimensions();
  const {notificationFields, is24HourFormat, androidLocale, visible, defaultGroupId, selectedGroupId} = props;
  const {umChangeNotificationMessageFromDate, umChangeNotificationMessageToDate, umChangeDisplayNotification} =
    notificationFields || {};
  const fromDateStr = umChangeNotificationMessageFromDate;
  const toDateStr = umChangeNotificationMessageToDate;
  const fromDate = umChangeDisplayNotification
    ? formatDateTime(new Date(fromDateStr), is24HourFormat, androidLocale)
    : '';
  const toDate = umChangeDisplayNotification ? formatDateTime(new Date(toDateStr), is24HourFormat, androidLocale) : '';
  let widthStyle = {};
  if (width < 400) {
    // Handle small devices
    widthStyle = ScaledSheet.create({
      maxW: {
        minWidth: '110@ms0.2',
        maxWidth: '110@ms0.2',
      },
    });
  }
  const showSetDefaultGroup = defaultGroupId !== selectedGroupId;
  return (
    <View>
      <Modal isVisible={visible} backdropOpacity={0} animationInTiming={1} animationOutTiming={0.01}>
        {visible && (
          <View style={modalStyle.container}>
            <View
              style={[
                {flexDirection: 'column', flex: 1, justifyContent: 'space-around'},
                !showSetDefaultGroup && modalStyle.addPadding,
              ]}>
              <Text style={modalStyle.titleText}>{I18n.t('login.selectUserGroup')}</Text>
              <SafeTouchableOpacity
                style={modalStyle.dropDownContainer}
                accessibilityLabel={'select_usergroup'}
                onPress={() => {
                  props.setVisible(false);
                  props.navigationPushAction('selectUserGroupScreen', {
                    selectedGroupId: props.selectedGroupId,
                    selectActive: true,
                    data: props.userGroups,
                  });
                }}>
                <Text style={modalStyle.textGroup} numberOfLines={1} ellipsizeMode="tail">
                  {props.selectedGroup}
                </Text>
                <Icon iconStyle={modalStyle.dropDown} name={'caret-down'} type={'font-awesome'} color={dhlBlack} />
              </SafeTouchableOpacity>

              {showSetDefaultGroup && (
                <SafeTouchableOpacity
                  key={makeDefault + 'key'}
                  accessibilityLabel={'check_box_press'}
                  style={modalStyle.setDefaultOpacity}
                  onPress={() => {
                    if (umChangeDisplayNotification) {
                      showToast(
                        I18n.t('notification.umChangeMessage', {
                          from: fromDate,
                          to: toDate,
                        }),
                      );
                    } else {
                      setMakeDefault(!makeDefault);
                    }
                  }}>
                  {isWeb ? (
                    <CheckBox color={dhlBasicOrange} value={makeDefault} accessibilityLabel={'check_box'} />
                  ) : (
                    <CheckBox
                      testID={'default_group_checkbox'}
                      tintColors={{true: dhlBasicOrange, false: dhlBasicOrange}}
                      disabled={true}
                      value={makeDefault}
                      accessibilityLabel={'check_box'}
                    />
                  )}
                  <Text style={modalStyle.text}>{' ' + I18n.t('login.setAsDefaultGroup')}</Text>
                </SafeTouchableOpacity>
              )}
            </View>

            {/*Buttons Row*/}
            <View style={modalStyle.buttonRow}>
              <ActionButton
                onPress={() => {
                  props.setVisible(false);
                  props.onContinueOrCancel(false);
                }}
                title={I18n.t('common.cancel')}
                containerViewStyle={widthStyle.maxW}
                type="Primary"
                accessibilityLabel={'cancel_button'}
              />
              <ActionButton
                onPress={async () => {
                  isDeviceOnline().then(isOnline => {
                    if (isOnline) {
                      props.setVisible(false);
                      props.onContinueOrCancel(true);
                      if (makeDefault) {
                        props.storeDefaultGroupAction(props.selectedGroupId);
                      }
                    } else {
                      showToast(I18n.t('fileTransport.offlineModeError'));
                    }
                  });
                }}
                title={I18n.t('common.continue')}
                containerViewStyle={widthStyle.maxW}
                type="Tertiary"
                accessibilityLabel={'continue_button'}
              />
            </View>
          </View>
        )}
      </Modal>
    </View>
  );
};

const modalStyle = ScaledSheet.create({
  container: {
    flexDirection: 'column',
    alignSelf: 'center',
    minHeight: '250@ms0.2',
    minWidth: isWeb ? '380@ms0.2' : '250@ms0.2',
    maxWidth: '600@ms0.2',
    marginHorizontal: '10@ms0.2',
    backgroundColor: dhlWhiteBasic,
    paddingHorizontal: '10@ms0.2',
    paddingVertical: '10@ms0.2',
    borderRadius: 10,
  },
  addPadding: {
    paddingBottom: '24@ms0.2',
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '18@ms0.2',
  },
  titleText: {
    alignSelf: 'center',
    color: dhlBlack,
    fontSize: '26@ms0.1',
    fontWeight: 'bold',
  },
  text: {
    fontSize: '20@ms0.2',
    color: dhlBlack,
    textAlign: 'center',
  },
  textGroup: {
    color: dhlBlack,
    fontSize: '20@ms0.2',
    maxWidth: '92%',
    paddingLeft: '6@ms0.2',
    paddingRight: '6@ms0.2',
  },
  setDefaultOpacity: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '8@ms0.2',
  },
  dropDown: {
    fontSize: '18@ms0.2',
    marginHorizontal: '8@ms0.2',
  },
  dropDownContainer: {
    marginTop: '26@ms0.2',
    borderColor: dhlBlack,
    borderBottomWidth: '1@ms0.2',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'center',
    textAlign: 'center',
    width: '80%',
    backgroundColor: dhlWhiteBasic,
  },
});

export default SelectUserGroupModal;
