import React, {useState} from 'react';
import {ActivityIndicator, Text, TextInput, useWindowDimensions, View} from 'react-native';
import Modal from 'react-native-modal';
import I18n from 'features/I18n';
import {ActionButton, dhlRedButton, dhlSeparate, dhlStatusGreen} from '@smartops/smartops-shared';
import {BackButton} from './BackButton';
import {ScaledSheet} from 'react-native-size-matters';
import {dhlBlack, dhlWhiteBasic} from './commonStyles';
import {logd, logi} from 'features/logging';
import {checkPassword} from 'flows/auth';
import {isDeviceOnline, showToast} from 'features/platformSpecific';

const initialState = {
  error: null, //Set translation key for error
  pinInput: null,
  wmsPassword: null,
  confirmWmsPassword: null,
  validating: false,
};

const WmsPasswordModal = props => {
  const [state, setState] = useState(initialState);
  const closeAndReset = () => {
    props.setVisible(false);
    setState({...initialState});
  };
  const setValue = (name, value) => setState(prevState => ({...prevState, [name]: value}));
  const {width} = useWindowDimensions();
  let widthStyle = {};
  if (width < 400) {
    // Handle small devices
    widthStyle = ScaledSheet.create({
      maxW: {
        minWidth: '130@ms0.2',
        maxWidth: '130@ms0.2',
      },
    });
  }

  const onlyMessageVisible = !!props.newPassApiResultMessage;
  const responseIsSuccess = props.newPassApiResultMessage === 'success';
  const responseIsError = props.newPassApiResultMessage != null && props.newPassApiResultMessage !== 'success';
  const validationErrExists = !!state.error;
  const validate = async () => {
    if (!state.pinInput) {
      if (props.isPinAuth) {
        setValue('error', 'wmsSettings.pinNotProvided');
      } else {
        setValue('error', 'wmsSettings.passwordNotProvided');
      }
      return false;
    }
    if (!state.wmsPassword) {
      setValue('error', 'wmsSettings.newWmsPasswordNotProvided');
      return false;
    }
    if (!state.confirmWmsPassword) {
      setValue('error', 'wmsSettings.confirmWmsPasswordNotProvided');
      return false;
    }
    if (props.isPinAuth && isNaN(state.pinInput)) {
      setValue('error', 'wmsSettings.wrongSmartopsPin');
      return false;
    }
    let isPinValid = await checkPassword(state.pinInput);
    if (!isPinValid) {
      if (props.isPinAuth) {
        setValue('error', 'wmsSettings.wrongSmartopsPin');
      } else {
        setValue('error', 'wmsSettings.wrongSmartopsPassword');
      }
      return false;
    }
    if (state.wmsPassword !== state.confirmWmsPassword) {
      setValue('error', 'wmsSettings.confirmWmsPasswordWrong');
      return false;
    }
    return true;
  };

  return (
    <View>
      <Modal isVisible={props.visible} backdropOpacity={0.4} animationInTiming={1} animationOutTiming={0.01}>
        {props.visible && !props.loading && (
          <View style={[modalStyle.container, onlyMessageVisible && modalStyle.onlyMessageChanges]}>
            {!onlyMessageVisible && (
              <View style={{flexDirection: 'column', flex: 1}}>
                <Text style={modalStyle.titleText}>{I18n.t('wmsSettings.setNewPassword')}</Text>
                <TextInput
                  style={modalStyle.input}
                  accessibilityLabel="pin_input"
                  underlineColorAndroid="rgba(0,0,0,0)"
                  autoCorrect={false}
                  autoCapitalize="none"
                  keyboardType={props.isPinAuth ? 'numeric' : 'default'}
                  placeholder={I18n.t(
                    props.isPinAuth ? 'wmsSettings.enterSmartopsPin' : 'wmsSettings.enterSmartopsPassword',
                  )}
                  placeholderTextColor="#323232"
                  onChangeText={text => {
                    setValue('pinInput', text);
                    state.error && setValue('error', null);
                  }}
                  value={state.pinInput}
                  maxLength={256}
                  secureTextEntry
                />
                <TextInput
                  style={modalStyle.input}
                  accessibilityLabel="new_password_input"
                  underlineColorAndroid="rgba(0,0,0,0)"
                  autoCorrect={false}
                  autoCapitalize="none"
                  placeholder={I18n.t('wmsSettings.enterWmsPassword')}
                  placeholderTextColor="#323232"
                  onChangeText={text => {
                    setValue('wmsPassword', text);
                    state.error && setValue('error', null);
                  }}
                  value={state.wmsPassword}
                  maxLength={100}
                  secureTextEntry
                />
                <TextInput
                  style={modalStyle.input}
                  accessibilityLabel="confirm_new_password_input"
                  underlineColorAndroid="rgba(0,0,0,0)"
                  autoCorrect={false}
                  autoCapitalize="none"
                  placeholder={I18n.t('wmsSettings.confirmWmsPassword')}
                  placeholderTextColor="#323232"
                  onChangeText={text => {
                    setValue('confirmWmsPassword', text);
                    state.error && setValue('error', null);
                  }}
                  value={state.confirmWmsPassword}
                  maxLength={100}
                  secureTextEntry
                />

                {state.error && (
                  <Text style={modalStyle.errorText} accessibilityLabel="password_error">
                    {I18n.t(state.error)}
                  </Text>
                )}
              </View>
            )}
            {onlyMessageVisible && (
              <View style={{flexDirection: 'column', flex: 1}}>
                <Text style={modalStyle.titleText}>{I18n.t('wmsSettings.setNewPassword')}</Text>
                <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                  <Text
                    style={[modalStyle.text, !responseIsSuccess ? {color: dhlRedButton} : {color: dhlStatusGreen}]}
                    accessibilityLabel="result_text">
                    {responseIsSuccess ? I18n.t('wmsSettings.sentAndSaved') : props.newPassApiResultMessage}
                  </Text>
                </View>
              </View>
            )}
            <View style={modalStyle.buttonRow}>
              {!responseIsSuccess && <BackButton onPress={() => closeAndReset()} titleKey={'common.cancel'} />}
              {responseIsSuccess && (
                <ActionButton
                  onPress={() => closeAndReset()}
                  title={I18n.t('common.ok')}
                  type="Tertiary"
                  containerViewStyle={widthStyle.maxW}
                  accessibilityLabel={'ok_button'}
                />
              )}
              {!responseIsSuccess && (
                <ActionButton
                  title={responseIsError ? I18n.t('common.resend') : I18n.t('common.save')}
                  disabled={state.validating}
                  onPress={async () => {
                    isDeviceOnline().then(async isOnline => {
                      if (isOnline) {
                        if (!state.validating) {
                          setValue('validating', true);
                          const validationRes = await validate();
                          logi(`new password length: ${state.wmsPassword && state.wmsPassword.length}`);
                          setValue('validating', false);
                          if (validationRes) {
                            props.onSave({
                              newPassword: state.wmsPassword,
                              identityData: props.identity,
                              appData: props.appData,
                            });
                          }
                        }
                      } else {
                        showToast(I18n.t('fileTransport.offlineModeError'));
                      }
                    });
                  }}
                  containerViewStyle={widthStyle.maxW}
                  accessibilityLabel={'save_new_password'}
                  type="Tertiary"
                />
              )}
            </View>
          </View>
        )}
        {props.visible && props.loading && (
          <ActivityIndicator size="large" accessibilityLabel="setting_password_indicator" />
        )}
      </Modal>
    </View>
  );
};

const modalStyle = ScaledSheet.create({
  container: {
    alignSelf: 'center',
    minWidth: '320@ms0.2',
    flexDirection: 'column',
    minHeight: '330@ms0.2',
    backgroundColor: dhlSeparate,
    paddingHorizontal: '16@ms0.2',
    paddingVertical: '10@ms0.2',
    borderRadius: 10,
  },
  onlyMessageChanges: {
    minHeight: '170@ms0.2',
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  titleText: {
    alignSelf: 'center',
    color: dhlBlack,
    fontSize: '26@ms0.2',
    fontWeight: 'bold',
    marginBottom: '8@ms0.2',
  },
  errorText: {
    alignSelf: 'center',
    textAlign: 'center',
    textAlignVertical: 'center',
    color: dhlRedButton,
    fontSize: '16@ms0.2',
    marginVertical: '8@ms0.2',
  },
  text: {
    alignSelf: 'center',
    textAlign: 'center',
    textAlignVertical: 'center',
    color: dhlBlack,
    fontSize: '16@ms0.2',
    marginVertical: '8@ms0.2',
    fontWeight: 'bold',
  },
  input: {
    marginTop: '8@ms0.2',
    height: '38@ms0.2',
    width: '80%',
    backgroundColor: dhlWhiteBasic,
    color: dhlBlack,
    borderRadius: 4,
    fontSize: '14@ms0.2',
    alignSelf: 'center',
  },
  iconStyle: {
    fontSize: '38@ms0.2',
  },
});

export default WmsPasswordModal;
