import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {View, Text, ScrollView, TouchableOpacity, TextInput, StyleSheet, useWindowDimensions} from 'react-native';
import FontAwesome5Pro from 'react-native-vector-icons/FontAwesome5Pro';
import {ScaledSheet} from 'react-native-size-matters';
import {dhlBlack, dhlBlue, dhlWhiteBasic, styles} from '../component/commonStyles';
import {ActionButton, dhlGrey, dhlRedButton, dhlSeparate, doubleClickSafe} from '@smartops/smartops-shared';
import OneOfSelect from '../component/OneOfSelect';
import I18n from 'features/I18n';
import Modal from 'react-native-modal';
import {BackButton} from '../component/BackButton';
import WmsPasswordModal from '../component/WmsPasswordModal';
import {setNewWmsPasswordAction} from '../flows/app';
import {setPassApiMessage} from 'features/wmsSso';
import {KEY_USER_PASSWORDS} from 'features/sharedPrefs';
import EncryptedStorage from 'react-native-encrypted-storage';
import {logd, logw} from 'features/logging';
import {checkPassword} from '../flows/auth';
import ActionModal from '../component/ActionModal';
import {formatDateTime} from '../utils/DateTime';

const selectorOptions = ['wmsSettings.smartScannerOption', 'wmsSettings.wmsScannerOption'];
const SafeTouchableOpacity = doubleClickSafe(TouchableOpacity);

async function getDecryptedPasswordById(passwordId) {
  try {
    const passwordsStr = await EncryptedStorage.getItem(KEY_USER_PASSWORDS);
    const passwords = JSON.parse(passwordsStr) || {};

    if (passwords && passwords[passwordId]) {
      logd(`getDecryptedPasswordById for passwordId: ${passwordId}`);
      return passwords[passwordId];
    } else {
      return null;
    }
  } catch (error) {
    // There was an error on the native side
    logw(`Error in getDecryptedPasswordById UI: `, error);
  }
}

const WmsSettingsScreen = props => {
  const {
    wmsData,
    wmsMetadata,
    passwordVisibilityDuration,
    settingNewPassInProgress,
    newPassApiResultMessage,
    wmsShowPassword,
    smartShowPassword,
    authType,
  } = props;
  const {
    smartConnectionsNumber,
    smartAccountsNumber,
    wmsConnectionNumber,
    wmsAccountsNumber,
    smartConnectionDetailsFailed,
    wmsConnectionDetailsFailed,
  } = wmsMetadata;
  const onlyWmsScannerAvailable = smartConnectionsNumber === 0 && smartAccountsNumber === 0;
  const onlySmartScannerAvailable = wmsConnectionNumber === 0 && wmsAccountsNumber === 0;
  const {wms_scanner, smart_scanner} = wmsData || {};
  let smartScannerData =
    smart_scanner &&
    smart_scanner.identity_providers[0] &&
    smart_scanner &&
    smart_scanner.identity_providers[0].identities;
  let wmsScannerData = wms_scanner && wms_scanner.identity_providers[0] && wms_scanner.identity_providers[0].identities;
  const [smartScannerSelected, setSmartScannerSelected] = useState(true);
  const onOptionSelected = option => setSmartScannerSelected(option === selectorOptions[0]);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [showNewPasswordPopup, setShowNewPasswordPopup] = useState(false);
  const [wmsPassword, setWmsPassword] = useState(''); //For the popup value
  const [identityToUpdate, setIdentityToUpdate] = useState();
  const showPassword = smartScannerSelected ? smartShowPassword : wmsShowPassword;
  const isPinAuth = authType === 'authenticationType.pinAuth';

  const isSmartScannerScreen =
    onlySmartScannerAvailable || (!onlySmartScannerAvailable && !onlyWmsScannerAvailable && smartScannerSelected);
  let currentData = isSmartScannerScreen ? smartScannerData : wmsScannerData;
  currentData =
    currentData &&
    currentData.sort(
      (a, b) =>
        (a.connection_name || '').toLowerCase().localeCompare((b.connection_name || '').toLowerCase()) ||
        (a.connection_name || '').localeCompare(b.connection_name || '') ||
        (a.username || '').toLowerCase().localeCompare((b.username || '').toLowerCase()),
    );

  const [showUmChangeNotification, setShowUmChangeNotification] = useState(false);
  const {notificationFields, is24HourFormat, androidLocale} = props;
  const {umChangeNotificationMessageFromDate, umChangeNotificationMessageToDate, umChangeDisplayNotification} =
    notificationFields;
  const fromDateStr = umChangeNotificationMessageFromDate;
  const toDateStr = umChangeNotificationMessageToDate;
  const fromDate = umChangeDisplayNotification
    ? formatDateTime(new Date(fromDateStr), is24HourFormat, androidLocale)
    : '';
  const toDate = umChangeDisplayNotification ? formatDateTime(new Date(toDateStr), is24HourFormat, androidLocale) : '';

  return (
    <View style={[styles.container, style.container]}>
      {!onlyWmsScannerAvailable && !onlySmartScannerAvailable && (
        <OneOfSelect
          onPress={onOptionSelected}
          buttonsStyle={style.buttonsStyle}
          textStyle={style.selectorText}
          containerStyle={{alignSelf: 'center'}}
          options={selectorOptions}
          indexOfSelected={smartScannerSelected ? 0 : 1}
        />
      )}
      {(onlyWmsScannerAvailable || onlySmartScannerAvailable) && (
        <Text style={style.appText}>
          {I18n.t(onlyWmsScannerAvailable ? 'wmsSettings.wmsScannerOption' : 'wmsSettings.smartScannerOption')}
        </Text>
      )}
      {(((onlySmartScannerAvailable || smartScannerSelected) && smartConnectionDetailsFailed) ||
        ((onlyWmsScannerAvailable || !smartScannerSelected) && wmsConnectionDetailsFailed)) && (
        <Text style={[style.errorText, {marginVertical: 6}]}>{I18n.t('wmsSettings.connectionsLoadingFailed')}</Text>
      )}

      {currentData && (
        <ScrollView>
          {currentData.map((identity, i) => (
            <View key={'identity_' + i} style={{flexDirection: 'column', marginTop: 4}}>
              {identity.connection_name &&
                (i > 0 ? currentData[i - 1].connection_name !== identity.connection_name : true) && (
                  <Text style={[style.label, i > 0 && style.connectionDivider]}>{identity.connection_name}</Text>
                )}
              <View style={{flexDirection: 'row', flex: 1, alignItems: 'center'}}>
                <View style={{flex: 2.2}}>
                  <Text style={style.text}>
                    {I18n.t(identity.username ? 'wmsSettings.wmsUsername' : 'errors.noWmsUser')}
                  </Text>
                  {identity.error && <Text style={[style.text, style.idErrorText]}>{I18n.t(identity.error)}</Text>}
                </View>

                <Text style={[style.text, {flex: 1.8}]}>{identity.username}</Text>
                {showPassword && (
                  <SafeTouchableOpacity
                    style={{
                      flexDirection: 'column',
                      flex: 1.2,
                      alignItems: 'center',
                      alignSelf: 'center',
                    }}
                    disabled={!identity.password || !identity.username || !!identity.error}
                    accessibilityLabel="wms_show_password"
                    onPress={async () => {
                      setWmsPassword(await getDecryptedPasswordById(identity.password));
                      setShowPasswordPopup(true);
                    }}>
                    <FontAwesome5Pro
                      name={'eye'}
                      type={'font-awesome5Pro'}
                      style={
                        !identity.password || !identity.username || !!identity.error ? style.disabledIcon : style.icon
                      }
                    />
                    <Text style={[style.simpleText, {marginTop: 2, textAlign: 'center'}]}>
                      {I18n.t('wmsSettings.showPassword')}
                    </Text>
                  </SafeTouchableOpacity>
                )}
                <SafeTouchableOpacity
                  style={{
                    flexDirection: 'column',
                    flex: 1.2,
                    alignItems: 'center',
                    alignSelf: 'center',
                  }}
                  disabled={!identity.username}
                  accessibilityLabel="wms_set_password"
                  onPress={() => {
                    if (umChangeDisplayNotification) {
                      setShowUmChangeNotification(true);
                    } else {
                      setIdentityToUpdate({identity, isSmartScanner: isSmartScannerScreen});
                      setShowNewPasswordPopup(true);
                    }
                  }}>
                  <FontAwesome5Pro
                    name={'user-lock'}
                    type={'font-awesome5Pro'}
                    style={!!identity.username ? style.icon : style.disabledIcon}
                  />
                  <Text style={[style.simpleText, {marginTop: 2, textAlign: 'center'}]}>
                    {I18n.t('wmsSettings.setNewPassword')}
                  </Text>
                </SafeTouchableOpacity>
              </View>
            </View>
          ))}
        </ScrollView>
      )}

      {/*Ask for account pin and show password*/}
      <ShowPasswordModal
        visible={showPasswordPopup}
        setVisible={setShowPasswordPopup}
        secondsVisible={passwordVisibilityDuration}
        wmsPassword={wmsPassword}
        isPinAuth={isPinAuth}
      />
      {/*Set new PIN modal*/}
      <WmsPasswordModal
        visible={showNewPasswordPopup}
        setVisible={val => {
          setShowNewPasswordPopup(val);
          props.setPassApiMessage(null);
        }}
        isPinAuth={isPinAuth}
        loading={settingNewPassInProgress}
        newPassApiResultMessage={newPassApiResultMessage}
        onSave={props.setNewWmsPasswordAction}
        identity={identityToUpdate}
        appData={currentData}
      />

      {/*Modal forUM change notification*/}
      <ActionModal
        titleKey={'notification.umChangeTitle'}
        visible={showUmChangeNotification}
        container={{minHeight: 280}}
        message={I18n.t('notification.umChangeMessage', {from: fromDate, to: toDate})}
        showCancelButton={true}
        cancelButtonKey={'common.ok'}
        setVisible={() => setShowUmChangeNotification(false)}
      />
    </View>
  );
};

const ShowPasswordModal = props => {
  const [showPass, setShowPass] = useState(false);
  const [passInput, setPassInput] = useState();
  const [showWrongPinError, setShowWrongPinError] = useState(false);
  const [showEmptyPinError, setShowEmptyPinError] = useState(false);
  const [counter, setCounter] = useState(0);
  const [timeoutId, setTimeoutId] = useState(undefined);
  const {width} = useWindowDimensions();
  let widthStyle = {};
  if (width < 400) {
    // Handle small devices
    widthStyle = ScaledSheet.create({
      maxW: {
        minWidth: '140@ms0.2',
        maxWidth: '140@ms0.2',
      },
    });
  }

  const closeAndReset = () => {
    if (timeoutId != null) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(undefined);
    props.setVisible(false);
    setPassInput(undefined);
    setShowWrongPinError(false);
    setShowPass(false);
  };

  const checkAndShowPass = isPinAuth => {
    if (!passInput) {
      setShowEmptyPinError(true);
      return;
    }
    if (isPinAuth && isNaN(passInput)) {
      setShowWrongPinError(true);
      return;
    }

    checkPassword(passInput).then(isValid => {
      if (isValid) {
        setShowPass(true);
        setCounter(props.secondsVisible);
        if (!timeoutId) {
          const id = setTimeout(() => closeAndReset(), 1000 * props.secondsVisible);
          setTimeoutId(id);
        }
      } else {
        setShowWrongPinError(true);
      }
    });
  };

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <View>
      <Modal isVisible={props.visible} backdropOpacity={0.4} animationInTiming={1} animationOutTiming={0.01}>
        {props.visible && (
          <View style={modalStyle.container}>
            <View style={{flexDirection: 'column', flex: 1}}>
              <Text style={modalStyle.titleText}>{I18n.t('wmsSettings.showPassword')}</Text>
              {!showPass && (
                <TextInput
                  style={modalStyle.input}
                  accessibilityLabel="password_input"
                  underlineColorAndroid="rgba(0,0,0,0)"
                  autoCorrect={false}
                  autoCapitalize="none"
                  keyboardType={props.isPinAuth ? 'numeric' : 'default'}
                  placeholder={I18n.t(
                    props.isPinAuth ? 'wmsSettings.enterSmartopsPin' : 'wmsSettings.enterSmartopsPassword',
                  )}
                  placeholderTextColor={dhlGrey}
                  onChangeText={text => {
                    setPassInput(text);
                    showWrongPinError && setShowWrongPinError(false);
                  }}
                  value={passInput}
                  secureTextEntry
                />
              )}
              {!showPass && (showWrongPinError || showEmptyPinError) && (
                <Text style={modalStyle.errorText} accessibilityLabel="pin_error">
                  {showWrongPinError
                    ? I18n.t(props.isPinAuth ? 'wmsSettings.wrongSmartopsPin' : 'wmsSettings.wrongSmartopsPassword')
                    : showEmptyPinError
                    ? I18n.t(props.isPinAuth ? 'wmsSettings.pinNotProvided' : 'wmsSettings.passwordNotProvided')
                    : ''}
                </Text>
              )}
              {showPass && (
                <Text style={modalStyle.passText} accessibilityLabel="wms_password_text">
                  {props.wmsPassword}
                </Text>
              )}

              {showPass && (
                <Text style={modalStyle.messageText} accessibilityLabel="window_will_close">
                  {I18n.t('wmsSettings.windowWillClose', {value: counter})}
                </Text>
              )}
            </View>
            <View style={modalStyle.buttonRow}>
              <BackButton
                onPress={() => closeAndReset()}
                titleKey={'common.cancel'}
                containerViewStyle={widthStyle.maxW}
              />
              {!showPass && (
                <ActionButton
                  title={I18n.t('wmsSettings.showPassword').toUpperCase()}
                  onPress={() => checkAndShowPass(props.isPinAuth)}
                  accessibilityLabel={'show_password_button'}
                  containerViewStyle={widthStyle.maxW}
                  type="Primary"
                />
              )}
            </View>
          </View>
        )}
      </Modal>
    </View>
  );
};

const style = ScaledSheet.create({
  container: {
    padding: '10@ms0.2',
  },
  buttonsStyle: {
    height: '50@ms0.2',
    width: '74%',
    borderRadius: 4,
    backgroundColor: dhlWhiteBasic,
    borderColor: dhlBlue,
  },
  label: {
    color: dhlBlack,
    fontSize: '20@ms0.2',
    fontWeight: 'bold',
  },
  text: {
    color: dhlBlack,
    fontSize: '15@ms0.2',
  },
  idErrorText: {
    color: dhlRedButton,
    fontSize: '13@ms0.2',
  },
  appText: {
    color: dhlBlack,
    textAlign: 'center',
    fontSize: '20@ms0.2',
  },
  errorText: {
    color: dhlRedButton,
    fontSize: '16@ms0.2',
    alignSelf: 'center',
  },
  icon: {
    fontSize: '26@ms0.2',
    color: dhlBlack,
  },
  disabledIcon: {
    fontSize: '26@ms0.2',
    color: 'grey',
  },
  selectorText: {
    fontSize: '18@ms0.2',
  },
  simpleText: {
    color: dhlBlack,
    fontSize: '12@ms0.2',
  },
  connectionDivider: {
    borderTopColor: 'black',
    borderTopWidth: StyleSheet.hairlineWidth,
    paddingTop: '10@ms0.2',
  },
});

const modalStyle = ScaledSheet.create({
  container: {
    alignSelf: 'center',
    flexDirection: 'column',
    minHeight: '190@ms0.2',
    backgroundColor: dhlSeparate,
    paddingHorizontal: '16@ms0.2',
    paddingVertical: '10@ms0.2',
    minWidth: '320@ms0.2',
    borderRadius: 10,
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  titleText: {
    alignSelf: 'center',
    color: dhlBlack,
    fontSize: '26@ms0.2',
    fontWeight: 'bold',
    marginBottom: '8@ms0.2',
  },
  errorText: {
    alignSelf: 'center',
    color: dhlRedButton,
    fontSize: '16@ms0.2',
    marginVertical: '8@ms0.2',
  },
  messageText: {
    alignSelf: 'center',
    color: dhlBlack,
    fontSize: '16@ms0.2',
    marginBottom: '8@ms0.2',
  },
  input: {
    height: '40@ms0.2',
    width: '68%',
    backgroundColor: dhlWhiteBasic,
    color: dhlBlack,
    borderRadius: 4,
    fontSize: '16@ms0.2',
    alignSelf: 'center',
  },
  passText: {
    height: '40@ms0.2',
    width: '68%',
    textAlignVertical: 'center',
    backgroundColor: dhlWhiteBasic,
    color: dhlBlack,
    borderRadius: 4,
    fontSize: '16@ms0.2',
    alignSelf: 'center',
  },
});

function mapStateToProps(state) {
  return {
    wmsData: state.app.wmsData,
    wmsMetadata: state.app.wmsMetadata,
    passwordVisibilityDuration: state.app.passwordVisibilityDuration,
    settingNewPassInProgress: state.wmsSso.settingNewPassInProgress,
    newPassApiResultMessage: state.wmsSso.newPassApiResultMessage,
    wmsShowPassword: state.wmsSso.wmsShowPassword,
    smartShowPassword: state.wmsSso.smartShowPassword,
    authType: state.auth.user.authType,
    notificationFields: state.notification,
    is24HourFormat: state.app.is24HourFormat,
    androidLocale: state.app.androidLocale,
  };
}

export default connect(mapStateToProps, {setNewWmsPasswordAction, setPassApiMessage})(WmsSettingsScreen);
