import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {KEY_APP_SETTINGS, loadJson} from '../features/sharedPrefs';
import {loadNotificationSettings} from './applicationNotification';

type SettingsValue = string | number | boolean;

export interface ParentSettings {
  [key: string]: SettingsValue | undefined;
}

interface SettingsState {
  settings: ParentSettings;
  settingsLoaded: boolean;
}

// actions
const SET_SETTINGS = 'SET_SETTINGS';

// initial state
const initialState: SettingsState = {
  settings: {},
  settingsLoaded: false,
};

// reducer
export const settingsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
        settingsLoaded: true,
      };
    default:
      return state;
  }
};

// selector
export const getSettingsSelector = (state: any) => state.settings.settings;
export const makeGetSettingSelector = (key: string) => (state: any) => state.settings.settings[key];

// action creators
export const setSettings = (settings: ParentSettings) => ({
  type: SET_SETTINGS,
  payload: settings,
});

// sagas
export function* loadSettings() {
  // @ts-ignore
  const settings = yield call(loadJson, KEY_APP_SETTINGS);
  const parentSettings = settings?.portal;
  yield put(setSettings(parentSettings));
  yield call(loadNotificationSettings);
}
