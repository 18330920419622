import React, {useEffect, useState} from 'react';
import {ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {connect, useDispatch} from 'react-redux';
import {dhlBlack, styles} from '../component/commonStyles';
import I18n from 'features/I18n';
import {ScaledSheet} from 'react-native-size-matters';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {dhlGrey, dhlRedButton, doubleClickSafe, dhlWhiteBasic, getAvailableLogs} from '@smartops/smartops-shared';
import {formatDateTime} from '../utils/DateTime';
import {
  clearFailedLogsAction,
  clearLogCountersAction,
  sendLogToApi,
  setSelectedLogLengthAction,
  stopSendLogToApi,
} from 'flows/app';
import {formatBytes} from 'utils/Utils';
import ActionModal from 'component/ActionModal';
import {loadValue} from 'features/sharedPrefs';
import {isDeviceOnline, isWeb, showToast} from 'features/platformSpecific';

const SafeTouchableOpacity = doubleClickSafe(TouchableOpacity);

const LogScreen = props => {
  const [allLogs, setAllLogs] = useState([]);
  const [lastSents, setLastSents] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState('');
  const [incComment, setIncComment] = useState('');
  const [btnsDisabled, setBtnsDisabled] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [failedLogsMessagess, setFailedLogsMessages] = useState([]);

  const {is24HourFormat, androidLocale, isSending, sendLogToApi, logSelectedCounter, logSentCounter, logFailedList} =
    props;

  //dispatch function
  const dispatch = useDispatch();

  //helper function for sum up object values
  const sumValues = obj => Object.values(obj).reduce((a, b) => a + b);

  //load all logs
  useEffect(() => {
    getAvailableLogs().then(res => {
      setAllLogs(res);
    });
  }, []);

  //load sent dates
  useEffect(() => {
    allLogs.forEach(log => {
      loadValue(`last_sent_${log.name}`).then(res => {
        const logKey = `${log.name}`;
        const newObject = {};
        newObject[logKey] = res;
        setLastSents(lastSents => ({...lastSents, ...newObject}));
      });
    });
  }, [allLogs, selectedLog]);

  //check if there were any fails during sending
  const failedLogsExists = Object.keys(logFailedList).length !== 0;

  //checking progress and showing result when it's done
  useEffect(() => {
    const progressMessage = I18n.t('log.ofSent', {num: logSentCounter, total: logSelectedCounter});
    setProgressMessage(progressMessage);

    if (logSelectedCounter === logSentCounter && logSelectedCounter !== 0) {
      dispatch(stopSendLogToApi());
      setModalVisible(false);
      setShowResult(true);
      if (!failedLogsExists) {
        setResultMessage(I18n.t('log.allSuccessful'));
      } else {
        const successfulLogsCount = logSentCounter - sumValues(logFailedList);
        setResultMessage(
          I18n.t('log.numberOfSuccessful', {
            succeed: successfulLogsCount,
            total: logSelectedCounter,
          }),
        );
        for (const [key, value] of Object.entries(logFailedList)) {
          setFailedLogsMessages(messages => [
            ...messages,
            I18n.t('log.filesFailed', {count: value, logName: I18n.t(`log.${key}`)}),
          ]);
        }
      }
    }
  }, [logSelectedCounter, logSentCounter]);

  //sum of all logs sizes
  const fullLogSize = allLogs.reduce((acc, o) => {
    return acc + o.totalSize;
  }, 0);

  //send style color for disabled buttons
  const sendStyle = btnsDisabled || isSending ? {color: dhlGrey} : null;

  //filter all logs to work just with relevant (with data)
  const allAvailableLogs = allLogs.filter(log => log.totalSize > 0);

  //send specific log to api
  const onSendLog = logger => {
    sendLogToApi({logger: logger, comment: incComment});
    const progressMessage = I18n.t('log.ofSent', {num: 1, total: 1});
    setProgressMessage(progressMessage);
  };

  //helper function for each in all
  const sendAllHandler = log => {
    props.sendLogToApi({logger: log.name, comment: incComment});
  };

  //send all logs
  const onSendAllLogs = () => {
    allAvailableLogs.forEach(sendAllHandler);
  };

  //select specific log
  const onSelectLog = log => {
    if (isWeb) {
      dispatch(setSelectedLogLengthAction(log.numberOfFiles));
    } else {
      dispatch(setSelectedLogLengthAction(1));
    }
    setModalVisible(true);
    setSelectedLog(log.name);
  };

  //all logs selected
  const onSelectAllLogs = () => {
    if (isWeb) {
      const allLogsLength = allAvailableLogs.map(l => l.numberOfFiles).reduce((s, l) => s + l, 0);
      dispatch(setSelectedLogLengthAction(allLogsLength));
    } else {
      dispatch(setSelectedLogLengthAction(allAvailableLogs.length));
    }
    setModalVisible(true);
    setSelectedLog('');
  };

  //close the result modal
  const onCloseResultModal = () => {
    setShowResult(false);
    setFailedLogsMessages([]);
    dispatch(clearLogCountersAction());
    dispatch(clearFailedLogsAction());
    setSelectedLog('');
    setIncComment('');
  };

  //text input component to send modal
  const TextInputComponent = (
    <TextInput
      multiline={true}
      style={style.textInput}
      placeholder={I18n.t('log.logCommentPlaceholder')}
      value={incComment}
      onChangeText={setIncComment}
    />
  );

  //component with list of logs which sending failed
  const FailedLogsMessageComponent = (
    <View style={style.failedLogsMessage}>
      {failedLogsMessagess.map(failedLog => (
        <Text style={style.error}>{failedLog}</Text>
      ))}
    </View>
  );

  return (
    //all logs
    <View style={[styles.container, style.container]}>
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 2, justifyContent: 'flex-end'}}>
          <Text accessibilityLabel="available_logs" style={style.text}>
            {I18n.t('log.logsOfAvailable', {
              num: allLogs.filter(log => log.totalSize > 0).length + '',
              bytes: formatBytes(fullLogSize, 1),
            })}
          </Text>
        </View>

        <SafeTouchableOpacity
          style={{flex: 1, flexDirection: 'column', alignItems: 'center'}}
          disabled={btnsDisabled || isSending}
          onPress={() => onSelectAllLogs()}
          accessibilityLabel="send_all_logs">
          <Icon name={'share-all'} type={'MaterialCommunityIcons'} style={[style.icon, sendStyle]} />
          <Text style={[style.text, sendStyle]}>{I18n.t('log.sendAll')}</Text>
        </SafeTouchableOpacity>

        {/* Sending Modal */}
        <ActionModal
          titleKey={isSending ? 'log.sending' : 'log.logCommentTitle'}
          visible={modalVisible}
          container={style.modalContainer}
          buttonStyle={style.modalButtons}
          showCustomComponent={isSending ? false : true}
          customComponent={TextInputComponent}
          showActivityIndicator={isSending ? true : false}
          message={isSending ? progressMessage : null}
          showCancelButton={true}
          showContinueButton={isSending ? false : true}
          cancelButtonKey={'common.cancel'}
          continueButtonKey={'log.send'}
          onContinue={async () => {
            isDeviceOnline().then(isOnline => {
              if (isOnline) {
                if (selectedLog !== '') {
                  onSendLog(selectedLog);
                } else {
                  onSendAllLogs();
                }
              } else {
                showToast(I18n.t('fileTransport.offlineModeError'));
              }
            });
          }}
          setVisible={() => setModalVisible(false)}
          backdropClose={isSending ? false : true}
          hideButtons={isSending ? true : false}
        />
        {/* Results Modal */}
        <ActionModal
          titleKey={'log.transmissionSummary'}
          visible={showResult}
          container={style.modalContainer}
          buttonStyle={style.modalButtons}
          message={resultMessage}
          showJustContinueButton={true}
          continueButtonKey={'common.ok'}
          onContinue={() => onCloseResultModal()}
          backdropClose={false}
          showCustomComponent={failedLogsExists}
          customComponent={FailedLogsMessageComponent}
        />
      </View>

      {/* individual logs */}
      <ScrollView>
        {allLogs.map(log => {
          if (log.totalSize > 0)
            return (
              <React.Fragment key={log.name}>
                <View
                  style={[
                    {
                      borderBottomColor: dhlGrey,
                      borderBottomWidth: StyleSheet.hairlineWidth,
                    },
                    style.margin,
                  ]}
                />
                <View>
                  <Text accessibilityLabel={`file_title`} style={style.itemTitle}>
                    {I18n.t(`log.${log.name}`)}
                  </Text>
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{flexDirection: 'column', flex: 2}}>
                      <View style={{flexDirection: 'row'}}>
                        <View style={{flex: 1}}>
                          <Text style={style.text}>{I18n.t('log.size')}</Text>
                        </View>
                        <View style={{flex: 1}}>
                          <Text accessibilityLabel={`size`} style={[style.text]}>
                            {formatBytes(log.totalSize, 1)}
                          </Text>
                        </View>
                      </View>
                      <View style={{flexDirection: 'row'}}>
                        <View style={{flex: 1}}>
                          <Text style={style.text}>{I18n.t('log.files')}</Text>
                        </View>
                        <View style={{flex: 1}}>
                          <Text accessibilityLabel={`files`} style={style.text}>
                            {log.numberOfFiles}
                          </Text>
                        </View>
                      </View>
                      <View style={{flexDirection: 'row'}}>
                        <View style={{flex: 1}}>
                          <Text style={style.text}>{I18n.t('log.lastSent')}</Text>
                        </View>
                        <View style={{flex: 1}}>
                          <Text style={[style.text]} accessibilityLabel={`time`}>
                            {lastSents[`${log.name}`]
                              ? formatDateTime(lastSents[`${log.name}`], is24HourFormat, androidLocale)
                              : 'N/A'}
                          </Text>
                        </View>
                      </View>
                    </View>

                    {/* Select log button */}
                    <SafeTouchableOpacity
                      style={{flex: 1, flexDirection: 'column', alignItems: 'center'}}
                      disabled={btnsDisabled || isSending}
                      accessibilityLabel={`send`}
                      onPress={() => onSelectLog(log)}>
                      <Icon name={'share'} type={'MaterialCommunityIcons'} style={[style.icon, sendStyle]} />
                      <Text style={[style.text, sendStyle]}>{I18n.t('log.send')}</Text>
                    </SafeTouchableOpacity>
                  </View>
                </View>
              </React.Fragment>
            );
        })}
      </ScrollView>
    </View>
  );
};

//local styles
const style = ScaledSheet.create({
  container: {
    paddingTop: '10@ms0.2',
    paddingLeft: '6@ms0.2',
  },
  margin: {
    marginRight: '10@ms0.2',
    marginTop: '4@ms0.2',
  },
  text: {
    color: dhlBlack,
    fontSize: '15@ms0.2',
  },
  error: {
    color: dhlRedButton,
    fontSize: '14@ms0.2',
  },
  itemTitle: {
    color: dhlBlack,
    fontWeight: 'bold',
    fontSize: '20@ms0.2',
  },
  icon: {
    fontSize: '26@ms0.2',
    color: dhlBlack,
  },
  textInput: {
    backgroundColor: dhlWhiteBasic,
    height: '50%',
    borderRadius: '10@ms0.2',
    padding: '5@ms0.2',
  },
  modalContainer: {
    backgroundColor: '#f2f2f2',
    width: '340@ms0.2',
    maxWidth: '98%',
    overflow: 'hidden',
    minHeight: '220@ms0.2',
  },
  modalButtons: {
    marginHorizontal: '5@ms0.2',
    minWidth: '130@ms0.2',
  },
  failedLogsMessage: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5@ms0.2',
  },
});

function mapStateToProps(state) {
  return {
    isSending: state.app.isSendingLog,
    logSelectedCounter: state.app.logSelectedCounter,
    logSentCounter: state.app.logSentCounter,
    logFailedList: state.app.logSentFailedList,
    is24HourFormat: state.app.is24HourFormat,
    androidLocale: state.app.androidLocale,
  };
}

export default connect(mapStateToProps, {sendLogToApi})(LogScreen);
