import {useDispatch} from 'react-redux';
import {ScrollView, Text, TextInput, TouchableOpacity, View} from 'react-native';

import {dhlBlue, dhlGrey, dhlStatusGreen, dhlWhiteBasic, navigationPushAction} from '@smartops/smartops-shared';
import React, {useEffect, useState} from 'react';
import Icon from 'react-native-vector-icons/FontAwesome';
import {ScaledSheet} from 'react-native-size-matters';
import i18n from '../features/I18n';
import {ALL_OPTION, SelectableItem} from './NotificationFilterScreen';

export interface NotificationFilterEnumPickerItem {
  name: string;
  label: string;
  selected: boolean;
  visible: boolean;
}

export enum FilteredEnumType {
  SEVERITY,
  TYPE,
}

export interface NotificationFilterEnumPickerProps {
  data: SelectableItem[];
  entityType: FilteredEnumType;
}

export const NotificationFilterEnumPickerScreen = (props: NotificationFilterEnumPickerProps) => {
  const [data, setData] = useState<NotificationFilterEnumPickerItem[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const dataToSet = props.data.map(it => {
      return {
        name: it.name,
        label: it.label,
        selected: it.selected,
        visible: true,
      };
    });

    setData(dataToSet);
  }, []);

  const changeSelected = (label: string) => {
    const dataToUpdate = data;
    const item = dataToUpdate.find(it => it.label === label);

    /// Handle All button
    if (item?.label !== ALL_OPTION) {
      const allItem = dataToUpdate.find(it => it.label === ALL_OPTION);
      if (allItem?.selected) allItem.selected = false;
    } else {
      /// reset all choices to false, if ALL, then only the one choice must be selected
      dataToUpdate.filter(it => it.label !== ALL_OPTION).forEach(it => (it.selected = false));
    }

    if (item) {
      item.selected = !item.selected;
    }
    setData([...dataToUpdate]);
  };
  const filterValues = (value: string) => {
    const dataToUpdate = data;
    if (value.length >= 2) {
      dataToUpdate.filter(it => it.name.toLowerCase().includes(value)).forEach(it => (it.visible = true));
      dataToUpdate.filter(it => !it.name.toLowerCase().includes(value)).forEach(it => (it.visible = false));
    } else {
      /// make all visible again
      dataToUpdate.forEach(it => (it.visible = true));
    }
    setData([...dataToUpdate]);
  };

  return (
    <>
      <ScrollView style={styles.wrapper}>
        <View style={styles.searchRow}>
          <Icon style={styles.searchIcon} name="search" size={16} />
          <TextInput style={{flex: 1}} onChangeText={text => filterValues(text)} />
        </View>
        {data
          .filter(it => it.visible)
          .map(it => {
            return (
              <View key={it.label}>
                <TouchableOpacity style={styles.itemButton} onPress={() => changeSelected(it.label)}>
                  <Text style={[styles.choiceText, {color: it.selected ? dhlBlue : dhlGrey}]}>{it.name}</Text>
                  {it.selected && (
                    <View style={styles.checkIconWrapper}>
                      <Icon name={'check'} size={20} color={dhlBlue} />
                    </View>
                  )}
                </TouchableOpacity>
                <View style={styles.line}></View>
              </View>
            );
          })}
      </ScrollView>
      <TouchableOpacity
        style={styles.applyButton}
        onPress={() => {
          console.log('Navigate to notifications wit filter: NotificationFilter');
          dispatch(
            navigationPushAction('notificationFilterScreen', {
              enumPickerData: {
                data: data,
                entityType: props.entityType,
              },
            }),
          );
        }}>
        <Text style={styles.applyText}>{i18n.t('notifications.apply')}</Text>
      </TouchableOpacity>
    </>
  );
};

const styles = ScaledSheet.create({
  wrapper: {
    flex: 1,
    width: '90%',
    alignSelf: 'center',
    marginTop: 20,
  },
  searchIcon: {alignSelf: 'center', paddingLeft: 5, paddingRight: 5},
  searchRow: {
    marginTop: 15,
    marginBottom: 20,
    borderColor: dhlGrey,
    borderWidth: 1,
    borderRadius: 5,
    width: '90%',
    alignSelf: 'center',
    flexDirection: 'row',
    height: 40,
  },
  choiceText: {
    fontSize: 15,
  },
  itemButton: {
    width: '90%',
    alignSelf: 'center',
    paddingTop: 12,
    paddingBottom: 12,
    flexDirection: 'row',
  },
  checkIconWrapper: {
    flex: 1,
    alignItems: 'flex-end',
  },
  selectAllText: {
    alignSelf: 'flex-end',
    padding: 20,
    textDecorationLine: 'underline',
    color: dhlBlue,
  },
  line: {backgroundColor: dhlGrey, width: '100%', height: 1},
  applyButton: {
    position: 'absolute',
    backgroundColor: dhlStatusGreen,
    paddingTop: 10,
    paddingBottom: 10,
    padding: 20,
    borderRadius: 5,
    bottom: 10,
    right: 10,
  },
  applyText: {color: dhlWhiteBasic, fontSize: 18},
});

export default NotificationFilterEnumPickerScreen;
