import React from 'react';
import {Text, View, useWindowDimensions, ActivityIndicator} from 'react-native';
import {ScaledSheet} from 'react-native-size-matters';
import {ActionButton, dhlBlack, dhlWhiteBasic} from '@smartops/smartops-shared';
import Modal from 'react-native-modal';
import I18n from '../features/I18n';
import {BackButton} from './BackButton';

const ActionModal = props => {
  const {
    visible = false,
    showContinueButton = false,
    continueButtonKey = 'common.continue',
    showCancelButton,
    setVisible,
    titleKey,
    messageKey,
    message,
    container = {},
    cancelButtonKey,
    onContinue,
    onCancel,
    buttonStyle = {},
    showCustomComponent = false,
    customComponent,
    backdropClose = true,
    showActivityIndicator = false,
    hideButtons = false,
    showJustContinueButton = false,
  } = props;
  const onCloseModal = () => setVisible(false);
  const {height, width} = useWindowDimensions();
  return (
    <View>
      <Modal
        isVisible={visible}
        onBackdropPress={backdropClose ? onCloseModal : null}
        backdropOpacity={0.4}
        animationInTiming={1}
        animationOutTiming={1}
        deviceHeight={height}
        deviceWidth={width}>
        <View style={[styles.container, container]}>
          <View style={{flexDirection: 'column', flex: 1}}>
            <Text style={styles.titleText}>{I18n.t(titleKey)}</Text>
            {showActivityIndicator && <ActivityIndicator />}
            <Text style={styles.messageText}>{messageKey ? I18n.t(messageKey) : message}</Text>
            {showCustomComponent && customComponent}
          </View>
          {!hideButtons && (
            <View style={styles.buttonRow}>
              {showCancelButton !== true && showJustContinueButton !== true && (
                <BackButton
                  onPress={() => setVisible(false)}
                  titleKey={'common.discard'}
                  capitalize
                  containerViewStyle={buttonStyle}
                />
              )}
              {showCancelButton === true && (
                <BackButton
                  onPress={() => {
                    if (onCancel !== undefined) {
                      onCancel();
                    }
                    setVisible(false);
                  }}
                  titleKey={cancelButtonKey || 'common.cancel'}
                  capitalize
                  containerViewStyle={buttonStyle}
                />
              )}
              {(showCancelButton !== true || showContinueButton === true || showJustContinueButton === true) && (
                <ActionButton
                  title={I18n.t(continueButtonKey)}
                  accessibilityLabel={'continueButton'}
                  type={'Tertiary'}
                  capitalize
                  onPress={() => onContinue()}
                  containerViewStyle={buttonStyle}
                />
              )}
            </View>
          )}
        </View>
      </Modal>
    </View>
  );
};

const styles = ScaledSheet.create({
  container: {
    flexDirection: 'column',
    alignSelf: 'center',
    minHeight: '190@ms0.2',
    maxWidth: '600@ms0.2',
    backgroundColor: dhlWhiteBasic,
    paddingHorizontal: '10@ms0.2',
    paddingVertical: '10@ms0.2',
    borderRadius: 10,
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  titleText: {
    textAlign: 'center',
    alignSelf: 'center',
    color: dhlBlack,
    fontSize: '24@ms0.2',
    fontWeight: 'bold',
    marginBottom: '8@ms0.2',
  },
  messageText: {
    alignSelf: 'center',
    color: dhlBlack,
    fontSize: '16@ms0.2',
    marginBottom: '8@ms0.2',
  },
});

export default ActionModal;
