import React, {useEffect, useState} from 'react';
import {Image, Text, TouchableOpacity, View} from 'react-native';
import {ScaledSheet} from 'react-native-size-matters';
import {dhlBasicOrange, dhlBlack, dhlGrey, dhlRedButton, navigationPushAction} from '@smartops/smartops-shared';
import I18n from '../features/I18n';
import {connect, useDispatch, useSelector} from 'react-redux';
import moment from 'moment/moment';
import {ApplicationNotificationData, NotificationGroupData} from '../utils/types';
import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5Pro';
import {formatDateTime} from '../utils/DateTime';
import {App} from '../types';
import {getApplicationNotifications, setIsNotificationsListOpen} from '../flows/applicationNotification';

interface NotificationGroupProps {
  app: App | undefined;
  item: NotificationGroupData;
  is24HourFormat: boolean | undefined;
  androidLocale: string | undefined;
  titleLengthLimit: number;
}

export const NotificationGroup = (props: NotificationGroupProps) => {
  const [notificationsVisible, setNotificationsVisible] = useState<boolean>(false);
  const applicationNotifications: ApplicationNotificationData = useSelector(getApplicationNotifications);
  const dispatch = useDispatch();

  const notificationSeveritiesColors =
    props.item.notifications
      ?.filter(it => it.severity && it.severity.colorHexa)
      ?.sort((a, b) => (a.severity?.order || 0) - (b.severity?.order || 0))
      ?.map(it => it.severity!.colorHexa) || [];

  const barColor =
    notificationSeveritiesColors && notificationSeveritiesColors.length > 0
      ? notificationSeveritiesColors[0]
      : dhlBasicOrange;

  useEffect(() => {
    setNotificationsVisible(
      applicationNotifications.notificationGroups
        .filter(it => it.appKey === props.item.appKey)
        .find(it => it.isNotificationsListOpen)?.isNotificationsListOpen || false,
    );
  }, [applicationNotifications.notificationGroups.filter(it => it.appKey === props.item.appKey)]);

  const getTitles = () => {
    const titles = props.item.notifications
      ? props.item.notifications
          .filter(it => it.title != undefined)
          .map(it =>
            it.title && it.title.length > props.titleLengthLimit
              ? it.title.substring(0, props.titleLengthLimit) + '...'
              : it.title,
          )
      : [];

    return [...new Set(titles)].join(', ');
  };

  const iconBase64 = props.item.settings?.iconBase64 ? props.item.settings.iconBase64 : props.app?.image;
  const notificationTitleFromSettings =
    props.item?.settings?.translationKey !== undefined &&
    I18n.t(props.item?.settings.translationKey) !== props.item?.settings.translationKey
      ? I18n.t(props.item?.settings.translationKey)
      : props.item.settings?.name;

  const countOfNotifications =
    props.item.notifications?.length && props.item.notifications?.length !== 0
      ? props.item.notifications?.length
      : props.item.countOfNotifications;
  return (
    <>
      <View style={styles.appWrapper}>
        <View style={[styles.yellowBar, {backgroundColor: barColor}]} />
        <Image source={{uri: iconBase64}} style={styles.icon} resizeMode={'contain'} />
        <View style={styles.textsBox}>
          <View style={{flexDirection: 'row'}}>
            <Text style={styles.notificationNumber}>{!props.item.loading ? countOfNotifications : 0}</Text>
            <Text numberOfLines={1} style={styles.notificationText}>
              {notificationTitleFromSettings
                ? notificationTitleFromSettings
                : I18n.t(`notifications.notification_text_${props.app?.key}`)}
            </Text>
          </View>
          <Text numberOfLines={1} style={styles.notificationTitles}>
            {getTitles()}
          </Text>
        </View>
        {props.item.notifications && notificationsVisible && (
          <TouchableOpacity
            style={styles.arrowBox}
            onPress={() => dispatch(setIsNotificationsListOpen(props.item.appKey, false))}>
            <FontAwesome5Icon name={'angle-down'} size={20} />
          </TouchableOpacity>
        )}
        {props.item.notifications && !notificationsVisible && (
          <TouchableOpacity
            style={styles.arrowBox}
            onPress={() => dispatch(setIsNotificationsListOpen(props.item.appKey, true))}>
            <FontAwesome5Icon name={'angle-right'} size={20} />
          </TouchableOpacity>
        )}
      </View>
      {notificationsVisible && props.item.notifications && (
        <View style={{marginLeft: 10, marginTop: 10}}>
          {props.item.notifications
            ?.sort(
              (a, b) =>
                (b.updated ? new Date(b.updated).valueOf() : -1) - (a.updated ? new Date(a.updated).valueOf() : -1),
            )
            ?.map(it => {
              return (
                <TouchableOpacity
                  key={it.id}
                  style={{margin: 5}}
                  onPress={() => dispatch(navigationPushAction('notificationDetailScreen', {notification: it}))}>
                  <View style={styles.titleBox}>
                    <View style={[styles.circle, {backgroundColor: it.severity?.colorHexa || 'grey'}]} />
                    <Text style={styles.titleText} numberOfLines={1}>
                      {it.title}
                    </Text>
                  </View>
                  <View style={styles.descriptionBox}>
                    <Text style={styles.descriptionText} numberOfLines={1}>
                      {it.updated ? formatDateTime(moment(it.updated), props.is24HourFormat, props.androidLocale) : ''}{' '}
                      · {it.description}
                    </Text>
                    <View style={styles.line}></View>
                  </View>
                </TouchableOpacity>
              );
            })}
        </View>
      )}
    </>
  );
};

const styles = ScaledSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
  },
  textsBox: {
    flex: 10,
    textAlign: 'left',
  },
  appWrapper: {
    flexDirection: 'row',
    height: 50,
    marginLeft: 5,
    marginTop: 10,
    alignItems: 'center',
  },
  yellowBar: {
    borderRadius: 10,
    height: '100%',
    width: 6,
    marginRight: 10,
  },
  notificationNumber: {
    marginRight: 5,
    marginLeft: 5,
    color: dhlRedButton,
    fontSize: 18,
    fontWeight: 'bold',
  },
  notificationText: {
    color: dhlBlack,
    fontSize: 18,
    fontWeight: 'bold',
  },
  notificationTitles: {
    marginLeft: 5,
  },
  emptyNotificationsText: {
    color: dhlBlack,
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  bellIconWrapper: {
    paddingTop: 30,
    paddingBottom: 20,
    alignItems: 'center',
  },
  bellIcon: {
    // @ts-ignore
    fontSize: '20@ms0.2',
  },
  icon: {
    height: '100%',
    width: 40,
    justifyContent: 'center',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: 10,
    marginRight: 8,
  },
  line: {
    marginTop: 3,
    backgroundColor: dhlGrey,
    height: 1,
    width: '100%',
  },
  titleBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleText: {
    color: dhlBlack,
    fontSize: 17,
  },
  descriptionBox: {
    marginTop: 5,
    marginLeft: 20,
  },
  descriptionText: {fontSize: 14, color: dhlBlack},
  arrowBox: {
    alignItems: 'flex-end',
    flex: 1.5,
    padding: 10,
  },
});

function mapStateToProps(state: any) {
  return {};
}

export default connect(mapStateToProps, {})(NotificationGroup);
