import React, {useState} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import {Icon} from 'react-native-elements';
import {dhlBlack} from '../component/commonStyles';
import I18n from 'features/I18n';
import {ScaledSheet} from 'react-native-size-matters';
import {clearQueue, getQueueLength} from 'react-native-multiprocess-preferences';
import {MenuScreen, navigationPushAction, navigationPopToAction} from '@smartops/smartops-shared';
import {logoutAttemptAction} from '../flows/login';
import {KIOSK_FOLDER} from '../config';
import ActionModal from '../component/ActionModal';
import {formatDateTime} from '../utils/DateTime';
import {isWeb} from 'features/platformSpecific';

const MenuContainer = props => {
  const [showUmChangeNotification, setShowUmChangeNotification] = useState(false);
  const [showUnsentMessage, setShowUnsentMessage] = useState(false);
  const {notificationFields, is24HourFormat, androidLocale, emergencyMode, folder, isAdmin, authType, userGroups} =
    props;
  const {umChangeNotificationMessageFromDate, umChangeNotificationMessageToDate, umChangeDisplayNotification} =
    notificationFields;
  if (!umChangeDisplayNotification && showUmChangeNotification) {
    setShowUmChangeNotification(false);
  }
  const fromDateStr = umChangeNotificationMessageFromDate;
  const toDateStr = umChangeNotificationMessageToDate;
  const fromDate = umChangeDisplayNotification
    ? formatDateTime(new Date(fromDateStr), is24HourFormat, androidLocale)
    : '';
  const toDate = umChangeDisplayNotification ? formatDateTime(new Date(toDateStr), is24HourFormat, androidLocale) : '';
  const isKioskFolderOrEmergency = folder === KIOSK_FOLDER || emergencyMode;
  const isPinAuth = authType === 'authenticationType.pinAuth';
  const isPwdAuth = authType === 'authenticationType.pwdAuth';
  const areGroupsAvailable = userGroups != null && userGroups.length > 0;

  const logoutFunc = async () => {
    !isWeb && clearQueue();
    if (isWeb) {
      window.history.replaceState({}, 'SmartOps', '/parent/logout');
    }
    props.logoutAttemptAction();
  };
  const logoutClick = async () => {
    const queueLength = isWeb ? 0 : await getQueueLength();
    if (queueLength > 0) {
      setShowUnsentMessage(true);
    } else {
      logoutFunc();
    }
  };

  const menuItems = [];
  menuItems.push({
    key: 1,
    action: () => props.navigationPopToAction('pageMain'),
    text: I18n.t('menu.back'),
    icon: <Icon name={'arrow-long-left'} type={'entypo'} iconStyle={styles.backIcon} color={dhlBlack} />,
    accessibilityLabel: 'back',
  });
  !isKioskFolderOrEmergency &&
    areGroupsAvailable &&
    menuItems.push({
      key: 2,
      action: () => props.navigationPushAction('userGroupManagementScreen'),
      text: I18n.t('userGroup.userGroupManagement'),
      accessibilityLabel: 'userGroupManagement',
    });
  !isKioskFolderOrEmergency &&
    (isPinAuth || isPwdAuth) &&
    !isAdmin &&
    menuItems.push({
      key: 3,
      action: () =>
        umChangeDisplayNotification ? setShowUmChangeNotification(true) : props.navigationPushAction('setPassScreen'),
      text: I18n.t(isPinAuth ? 'menu.changePin' : 'menu.changePwd'),
      accessibilityLabel: 'changePin',
    });
  !isKioskFolderOrEmergency &&
    !isWeb &&
    menuItems.push({
      key: 4,
      action: () => props.navigationPushAction('pageChoose', {back: true}),
      text: I18n.t('menu.editAppsView'),
      accessibilityLabel: 'editApps',
    });
  !isKioskFolderOrEmergency &&
    menuItems.push({
      key: 5,
      action: () => props.navigationPushAction('pageSettings', {back: true}),
      text: I18n.t('menu.settings'),
      accessibilityLabel: 'settings',
    });
  !isKioskFolderOrEmergency &&
    menuItems.push({
      key: 6,
      action: () => logoutClick(),
      text: I18n.t('menu.logout'),
      accessibilityLabel: 'logout',
    });
  menuItems.push({
    key: 7,
    action: () => props.navigationPushAction('aboutScreen', {back: true}),
    text: I18n.t('menu.about'),
    accessibilityLabel: 'about',
  });
  return (
    <View style={{flex: 1}}>
      <ActionModal
        titleKey={'notification.umChangeTitle'}
        visible={showUmChangeNotification}
        container={styles.umChangeModal}
        message={I18n.t('notification.umChangeMessage', {
          from: fromDate,
          to: toDate,
        })}
        showCancelButton={true}
        cancelButtonKey={'common.ok'}
        setVisible={() => setShowUmChangeNotification(false)}
      />
      <ActionModal
        titleKey={'menu.logout'}
        visible={showUnsentMessage}
        container={styles.unsentMessageModal}
        message={I18n.t('menu.unsentMessage')}
        showCancelButton={true}
        cancelButtonKey={'common.cancel'}
        continueButtonKey={'login.logout'}
        showContinueButton={true}
        onContinue={async () => {
          setShowUnsentMessage(false);
          await logoutFunc();
        }}
        setVisible={bool => setShowUnsentMessage(bool)}
      />
      <MenuScreen {...props} menuItems={menuItems} />
    </View>
  );
};

const styles = ScaledSheet.create({
  backIcon: {
    fontSize: '26@ms0.2',
  },
  umChangeModal: {
    minHeight: '280@ms0.2',
  },
  unsentMessageModal: {
    minHeight: '180@ms0.2',
  },
});

function mapStateToProps(state) {
  return {
    notificationFields: state.notification,
    is24HourFormat: state.app.is24HourFormat,
    androidLocale: state.app.androidLocale,
    emergencyMode: state.app.emergencyMode,
    folder: state.app.folder,
    isAdmin: state.auth.user.isAdmin,
    authType: state.auth.user.authType,
    userGroups: state.auth.userGroups,
  };
}

export default connect(mapStateToProps, {logoutAttemptAction, navigationPushAction, navigationPopToAction})(
  MenuContainer,
);
