import React from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {ScaledSheet} from 'react-native-size-matters';
import {dhlRedButton, dhlWhiteBasic, doubleClickSafe} from '@smartops/smartops-shared';
import I18n from '../features/I18n';
import {connect} from 'react-redux';

const SafeTouchableOpacity = doubleClickSafe(TouchableOpacity);

const BottomBanner = (props: any) => {
  const {isVisible, message, callback} = props.bottomBannerConfig;

  return isVisible ? (
    <View style={styles.container}>
      <Text style={styles.text}>{message}</Text>
      <SafeTouchableOpacity
        style={styles.button}
        // @ts-ignore
        onPress={callback != null ? () => callback() : undefined}>
        <Text style={[styles.text, {fontWeight: 'bold'}]}>{I18n.t('common.retry')}</Text>
      </SafeTouchableOpacity>
    </View>
  ) : (
    <></>
  );
};

const styles = ScaledSheet.create({
  container: {
    minHeight: '30@ms0.2',
    minWidth: '100%',
    backgroundColor: dhlRedButton,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
  },
  text: {
    color: dhlWhiteBasic,
    fontSize: '16@ms0.2',
  },
  button: {
    alignItems: 'flex-end',
    marginLeft: '20@ms0.2',
    padding: '18@ms0.2',
  },
});

function mapStateToProps(state: any) {
  return {
    bottomBannerConfig: state.app.bottomBannerConfig,
  };
}

export default connect(mapStateToProps, {})(BottomBanner);
