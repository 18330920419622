import {StyleSheet, Text, View} from 'react-native';
import {dhlBasicOrange, dhlBlack, dhlRedButton} from '@smartops/smartops-shared';
import I18n from '../features/I18n';

interface EmergencyModeTopBarProps {
  pageTitle?: string;
  showEmergencyModeText: boolean;
}

const EmergencyModeTopBar = (props: EmergencyModeTopBarProps) => {
  return (
    <View style={styles.container}>
      <View style={styles.part}>
        <Text style={[styles.leftText, {color: dhlRedButton}]}>OFFLINE</Text>
        {props.showEmergencyModeText ? (
          <Text style={styles.leftText}>{I18n.t('sceneTitles.emergencyMode')}</Text>
        ) : (
          <View></View>
        )}
      </View>
      <Text style={[styles.pageTitle, styles.part]}>{props.pageTitle}</Text>
      <View style={styles.part} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: dhlBasicOrange,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    paddingHorizontal: 10,
  },
  leftText: {
    fontSize: 16,
    color: dhlBlack,
    marginHorizontal: 5,
  },
  pageTitle: {
    fontSize: 16,
  },
  part: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
export default EmergencyModeTopBar;
