import React from 'react';
import I18n from 'features/I18n';
import {dhlGrey} from './commonStyles';
import PropTypes from 'prop-types';
import {ActionButton, dhlWhiteBasic, navigationPopAction} from '@smartops/smartops-shared';
import {ScaledSheet} from 'react-native-size-matters';

export const BackButton = props => {
  const {capitalize = false, containerViewStyle} = props;
  const title = capitalize ? I18n.t(props.titleKey).toUpperCase() : I18n.t(props.titleKey);
  return (
    <ActionButton
      title={title}
      accessibilityLabel={'backButton'}
      backgroundColor={dhlGrey}
      buttonStyle={styles.buttonStyle}
      textStyle={{color: dhlWhiteBasic}}
      containerViewStyle={containerViewStyle}
      onPress={() => {
        props.onPress ? props.onPress() : navigationPopAction();
      }}
    />
  );
};

const styles = ScaledSheet.create({
  buttonStyle: {},
});

BackButton.propTypes = {
  onPress: PropTypes.func,
  titleKey: PropTypes.string,
};
