import React from 'react';
import {connect} from 'react-redux';
import {View, ScrollView, Text, TouchableOpacity, Linking} from 'react-native';
import {ScaledSheet} from 'react-native-size-matters';
import {dhlBlack, styles} from '../component/commonStyles';
import I18n from 'features/I18n';
import {
  ActionButton,
  dhlBlue,
  doubleClickSafe,
  navigationPopAction,
  navigationPopToAction,
} from '@smartops/smartops-shared';
import {BackButton} from '../component/BackButton';
import {logi, logw} from '../features/logging';
import * as SendIntentAndroid from 'react-native-send-intent';
import {startApp} from 'react-native-multiprocess-preferences';
import {reinitializationAttemptAction} from '../flows/login';
import Metrics from 'features/metrics';
import {isWeb} from 'features/platformSpecific';
import {openLiteApp} from '../utils/UtilsTS';

const SafeTouchableOpacity = doubleClickSafe(TouchableOpacity);

const openUrl = async (app, webPaths) => {
  const url = app.url;
  if (isWeb) {
    await openLiteApp(app.key, webPaths);
  } else {
    Linking.canOpenURL(url)
      .then(supported => {
        if (!supported) {
          logi(`Linking can't handle url: ${url}`);
          logi('Trying with SendIntentAndroid...');
          SendIntentAndroid.openApp(url).then(wasOpened => logi(`DataWillBeLostScreen wasOpened: ${wasOpened}`));
        } else {
          logi(`Else opening`, url === url.toLowerCase());
          if (url === url.toLowerCase()) {
            return Linking.openURL(url);
          } else {
            return startApp(url);
          }
        }
      })
      .catch(err => logw('An error occurred', err));
  }
};

const DataWillBeLostScreen = props => {
  const {apps, runningAppsKeys, selectedUserGroupId, reinitializationAttemptAction, webPaths, availableWebAppKeys} =
    props;
  apps.forEach(app => logi(app.key));
  logi(`runningAppsKeys: `, runningAppsKeys);
  const runningApps = isWeb
    ? apps.filter(app => runningAppsKeys.includes(app.key) && availableWebAppKeys.includes(app.key.toLowerCase()))
    : apps.filter(app => runningAppsKeys.includes(app.key));
  const appsNames = runningApps.map(a => a.name);
  return (
    <View style={[styles.container, style.container]}>
      <View style={style.textView}>
        <Text style={style.text}>{appsNames.join(', ') + ' ' + I18n.t('userGroup.dataWillBeLostText')}</Text>
      </View>
      <ScrollView style={style.appView}>
        {runningApps.map((app, i) => (
          <SafeTouchableOpacity
            key={'key_' + i}
            onPress={async () => {
              if (runningApps.length === 1) {
                props.navigationPopAction('userGroupManagementScreen');
              }
              await openUrl(app, webPaths);
            }}
            style={{marginBottom: 8}}>
            <Text style={style.label}>{I18n.t('common.complete') + ' ' + app?.name}</Text>
          </SafeTouchableOpacity>
        ))}
      </ScrollView>
      <View style={style.buttonRow}>
        <BackButton
          onPress={() => props.navigationPopToAction('userGroupManagementScreen')}
          titleKey={'common.cancel'}
        />
        <ActionButton
          onPress={() => {
            Metrics.trackEvent(Metrics.EventType.UserGroupSwitch, {usergroup_id: selectedUserGroupId});
            Metrics.storeUserGroupId(selectedUserGroupId);
            reinitializationAttemptAction(selectedUserGroupId);
            props.navigationPopToAction('userGroupManagementScreen');
          }}
          title={I18n.t('userGroup.changeGroup')}
          type="Primary"
          accessibilityLabel={'change_group'}
        />
      </View>
    </View>
  );
};

const style = ScaledSheet.create({
  container: {
    padding: '10@ms0.2',
  },
  textView: {
    marginBottom: '10@ms0.2',
    padding: '10@ms0.2',
  },
  appView: {
    flex: 1,
    padding: '10@ms0.2',
  },
  label: {
    color: dhlBlue,
    fontSize: '20@ms0.2',
    textDecorationLine: 'underline',
  },
  text: {
    color: dhlBlack,
    fontSize: '18@ms0.2',
  },
  appText: {
    color: dhlBlack,
    textAlign: 'center',
    fontSize: '20@ms0.2',
  },
  buttonRow: {
    marginTop: '8@ms0.2',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

function mapStateToProps(state) {
  return {
    apps: state.apps.appsDetails,
    runningAppsKeys: state.apps.runningAppsKeys,
    webPaths: state.app.webPaths,
    availableWebAppKeys: state.app.availableWebAppKeys,
  };
}

export default connect(mapStateToProps, {reinitializationAttemptAction, navigationPopAction, navigationPopToAction})(
  DataWillBeLostScreen,
);
