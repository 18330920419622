import {ALL_APPS, getAppSettingsVal} from '../flows/apps';
import {KEY_USER_JSON_RESP, loadJson, saveValue, SELECTED_USER_GROUP_OBJECT} from './sharedPrefs';
import {get} from 'lodash';
import {isDeviceOnline, showToast} from './platformSpecific';
import {GroupAPI} from '../utils/types';
import I18n from './I18n';
import {logi, logd, logw} from './logging';

interface FieldI {
  init_file_version?: string;
  app_role?: string[]; // If the user has one of the roles, then run this check
  path: string;
  translation_key?: string;
}

const operators = ['==', '!=', '>', '<', '>=', '<='];

/*
//For testing, comment out the imports
const loadJson = async (key: any) => testInit;
const doPing = async () => true;
const KEY_USER_JSON_RESP = '';
const ALL_APPS = [{label: 'inc_mng', initDataKey: 'incident_management'}];
const getAppSettingsVal = async (key: string) => '[{"path":"[*].first[*].second[*].third[*].fourth.id.ids[*].id","translation_key":"translation_key"}]';
//'[{"path":"[*].first[*].second[*].third[*].fourth.id.ids[*].id","translation_key":"translation_key"}]'
//'[{"path":"first[*].second[*].third[*].fourth.id.ids[*].id","translation_key":"translation_key"}]'
//'[{"path":"[*].statements[*].id","translation_key":"translation_key"}]'
//'[{"path":"safetyCodesAndCategories[*].safetyCategory.id","translation_key":"bbs_safety_categories_ids"}]'
*/
/**
 * Returns false if internet connection is needed for this app, and we are offline
 * Returns true if we are online or no internet connection is needed for this app
 * @param appKey
 * @task SO-4993 Connection check before child app opening
 */
export async function internetConnectionCheck(appKey: string): Promise<boolean> {
  logd(`~~appStartValidation::internetConnectionCheck~~`);
  const cdmKey = `${appKey.toLowerCase()}_start_without_connection`;
  const val: string = await getAppSettingsVal(cdmKey);
  if (val === 'false') {
    const isOnline = await isDeviceOnline();
    logd(`internetConnectionCheck isOnline: ${isOnline}`);
    return isOnline;
  }
  return true;
}

export async function runAllAppsValidationsAndPersistErrorMessage(): Promise<boolean> {
  //logd(`>>>> runAllAppsValidationsAndPersistErrorMessage start`);
  for (const app of ALL_APPS) {
    // Init File Check
    const isFilePresent = await isInitFilePresent(app.label);
    let errorMessage;
    if (!isFilePresent) {
      errorMessage = I18n.t('errors.appStartMissingInitData');
      await saveValue(app.validationMessageKey, errorMessage);
      continue; //Skip the rest of the checks
    }
    // Init File Mandatory Fields Check
    const mandatoryFieldsCheck = await initFileMandatoryFieldsCheck(app.label);
    if (!mandatoryFieldsCheck.isValid) {
      errorMessage = I18n.t([mandatoryFieldsCheck.errorKey || '', 'errors.noPermissionOnApp']);
      await saveValue(app.validationMessageKey, errorMessage);
      continue; //Skip the rest of the checks
    }
    // User Profile Mandatory Fields Check
    const mandatoryUserFieldsCheck = await userProfileMandatoryFieldsCheck(app.label);
    if (!mandatoryUserFieldsCheck.isValid) {
      errorMessage = I18n.t([mandatoryUserFieldsCheck.errorKey || '', 'mandatoryFields.defaultMissingUserField']);
      await saveValue(app.validationMessageKey, errorMessage);
    } else {
      //Clear any previous error
      await saveValue(app.validationMessageKey, null);
    }
  }
  //logd(`>>>> runAllAppsValidationsAndPersistErrorMessage end`);
  return true;
}

export async function clearAllValidationMessages(): Promise<void> {
  for (const app of ALL_APPS) {
    await saveValue(app.validationMessageKey, null);
  }
}

/**
 * Returns false if init file is needed for the app to start, and it is not present
 * Returns true if it is present, or it is not needed for the app to start
 * @param appKey
 * @task SO-4995 Init file check before child app opening
 */
export async function isInitFilePresent(appKey: string): Promise<boolean> {
  //logd(`~~appStartValidation::isInitFilePresent~~`);
  const cdmKey = `${appKey.toLowerCase()}_start_without_init_file`;
  const val: string = await getAppSettingsVal(cdmKey);
  if (val != null && val !== 'true') {
    const initDataKey = ALL_APPS.find(app => app.label === appKey)?.initDataKey;
    if (initDataKey) {
      const initData: string = await loadJson(initDataKey); // Set to '' during jest testing
      if (initData) {
        return true;
      }
    }
    return false;
  }
  return true;
}

export async function initFileMandatoryFieldsCheck(appKey: string): Promise<{isValid: boolean; errorKey?: string}> {
  logi(`appStartValidation::initFileMandatoryFieldsCheck for app ${appKey}`);
  const cdmKey = `${appKey.toLowerCase()}_start_mandatory_init_fields`;
  const val: string = await getAppSettingsVal(cdmKey);
  if (val != null) {
    const validationRules: FieldI[] = JSON.parse(val);
    const initDataKey = ALL_APPS.find(app => app.label === appKey)?.initDataKey;
    const initDataUrlPath = ALL_APPS.find(app => app.label === appKey)?.urlPath;
    if (initDataKey) {
      const initDataK = initDataKey === 'weblinks' ? initDataKey + '_root' : initDataKey;
      const initData: any = await loadJson(initDataK); // Already parsed
      if (initData) {
        let isInitValid = true;
        let isValid = false;
        let errorKey = 'default';
        for (let i = 0; i < validationRules.length && isInitValid; i++) {
          const validationRule = validationRules[i];

          if (
            validationRule.init_file_version != null &&
            initDataUrlPath != null &&
            !initDataUrlPath.startsWith(`/${validationRule.init_file_version}/`)
          ) {
            continue;
          }

          if (validationRule.app_role != null) {
            const selectedUg: GroupAPI = await loadJson(SELECTED_USER_GROUP_OBJECT);
            if (selectedUg != null && selectedUg.authorizations != null) {
              const userAppRole = selectedUg.authorizations.find(auth => auth.role?.application?.label === appKey)?.role
                ?.label;
              logd(`userAppRole: `, userAppRole);
              if (userAppRole == null) {
                logw(`Init data validation failed - no assigned group was found`);
                return {isValid: false, errorKey: `mandatoryFields.${validationRule.translation_key}`};
              } else if (validationRule.app_role.includes(userAppRole)) {
                logd(`App_role check is valid, continue`);
              } else if (!validationRule.app_role.includes(userAppRole)) {
                logd(`User role is not in list of roles to be checked, skipping this check`);
                continue;
              }
            } else {
              logd(`No assigned group was found, skipping this check`);
              continue;
            }
          }

          if (!validationRule.path.includes('[*]')) {
            if (
              get(initData, validationRule.path, null) != null &&
              get(initData, validationRule.path, null).toString().trim().length !== 0
            ) {
              isValid = true;
            } else {
              logw(`Init data validation failed - path: ${validationRule.path}`);
              validationRule.translation_key && (errorKey = validationRule.translation_key);
              isInitValid = false;
            }
          } else {
            let path = validationRule.path;
            const pathFirstPart = path.substring(0, path.indexOf('[*].'));
            let data = initData;
            if (pathFirstPart.length > 0) {
              path = path.substring(path.indexOf('[*].'), path.length);
              data = get(initData, pathFirstPart, null);
            }
            //logd(`1st arrayIteration path: ${path}, data: `, JSON.stringify(data));
            const res = arrayIteration(path, validationRule, data, isValid, errorKey);
            isValid = res.isValid;
            isInitValid = res.isValid;
            errorKey = res.errorKey;
            if (!isInitValid) {
              logw(`Init data validation failed on path: ${validationRule.path}`);
            }
            //logd(`isValid: ${isValid}, isInitValid: ${isInitValid} errorKey: ${errorKey}`);
          }
          isValid = false;
        }
        return {isValid: isInitValid, ...(!isInitValid && {errorKey: `mandatoryFields.${errorKey}`})};
      }
    }
    return {isValid: false, errorKey: ''};
  }
  return {isValid: true};
}

function arrayIteration(path: string, field: any, initData: any, isValid: boolean, errorKey: string) {
  //iterate array in init data
  const pathHasArray = path.includes('[*]');
  const pathFirstPart = path.substring(0, path.indexOf('[*].'));
  const pathLastPart = path.substring(path.indexOf('[*].') + 4, path.length);
  //logd(`>>> path: ${path}, pathFirstPart: ${pathFirstPart}, pathLastPart: ${pathLastPart}, isValid: ${isValid}`);
  field.translation_key && (errorKey = field.translation_key);
  if (pathFirstPart.length === 0) {
    // Init data is array
    const arr = initData;
    if (arr == null || arr.length === 0) {
      // If the array is null or empty, show error
      isValid = false;
      return {isValid, errorKey};
    }
    for (let j = 0; j < arr.length && !isValid; j++) {
      if (
        !pathLastPart.includes('[*]') &&
        get(arr[j], pathLastPart, null) != null &&
        get(arr[j], pathLastPart, null).toString().trim().length !== 0
      ) {
        isValid = true;
      } else if (pathLastPart.includes('[*]')) {
        const pathNextPart = pathLastPart.substring(0, pathLastPart.indexOf('[*].'));
        const path = pathLastPart.substring(pathLastPart.indexOf('[*].'), pathLastPart.length);
        //logd(`first pathNextPart: ${pathNextPart}, initdata: `, get(arr[j], pathNextPart, null));
        const res = arrayIteration(path, field, get(arr[j], pathNextPart, null), isValid, errorKey);
        isValid = res.isValid;
        errorKey = res.errorKey;
      } else if (!pathLastPart.includes('[*]') && conditionInBrackets(pathLastPart)) {
        // Validate condition
        let valueWasFound = false;
        for (let j = 0; j < arr.length && !valueWasFound; j++) {
          const path = pathFirstPart + '[' + j + ']';
          valueWasFound = findValue(pathLastPart, initData, path);
        }
        if (!valueWasFound) {
          isValid = false;
          field.translation_key && (errorKey = field.translation_key);
        } else {
          isValid = true;
        }
      } else {
        logd(`VALID LOOP, CONTINUE path: ${path}, pathLastPart: ${pathLastPart}, arr: `, JSON.stringify(arr));
      }
    }
  } else if (!pathHasArray && get(initData, path, null) == null) {
    isValid = false;
  } else if (!Array.isArray(initData[pathFirstPart])) {
    logd(`initFileMandatoryFieldsCheck error: Not an array, path: ${path}, pathFirstPart: ${pathFirstPart}`);
    isValid = false;
  } else {
    logd(
      `ELSE, path: ${path}, pathFirstPart: ${pathFirstPart}, pathLastPart: ${pathLastPart}, initData: `,
      JSON.stringify(initData),
    );
  }
  return {isValid, errorKey};
}

export function conditionInBrackets(str: string): boolean {
  // Regex: \[(.*?)\]   find: []
  const regex = new RegExp('\\[(.*?)\\]');
  return regex.test(str);
}

export async function userProfileMandatoryFieldsCheck(appKey: string): Promise<{
  isValid: boolean;
  errorKey?: string;
}> {
  logd(`~~appStartValidation::userProfileMandatoryFieldsCheck~~`);
  const cdmKey = `${appKey.toLowerCase()}_start_mandatory_user_fields`;
  const val: string = await getAppSettingsVal(cdmKey);
  if (val != null) {
    const fields: FieldI[] = JSON.parse(val);
    const userData: any = await loadJson(KEY_USER_JSON_RESP); // Already parsed
    if (userData) {
      let areUserDataValid = true;
      let isValid = false;
      let errorKey = 'defaultMissingUserField';
      for (let i = 0; i < fields.length && areUserDataValid; i++) {
        const field = fields[i];
        if (!field.path.includes('[*]') && !conditionInBrackets(field.path)) {
          if (
            get(userData, field.path, null) != null &&
            get(userData, field.path, null).toString().trim().length !== 0
          ) {
            isValid = true;
          } else {
            field.translation_key && (errorKey = field.translation_key);
            areUserDataValid = false;
          }
        } else if (field.path.includes('[*]')) {
          //iterate array in init data
          let path = field.path;
          const pathFirstPart = path.substring(0, path.indexOf('[*].'));
          let data = userData;
          if (pathFirstPart.length > 0) {
            path = path.substring(path.indexOf('[*].'), path.length);
            data = get(userData, pathFirstPart, null);
          }
          logd(`1st arrayIteration path: ${path}, data: `, JSON.stringify(data));
          const res = arrayIteration(path, field, data, isValid, errorKey);
          isValid = res.isValid;
          areUserDataValid = res.isValid;
          errorKey = res.errorKey;
          logd(`isValid: ${isValid}, areUserDataValid: ${areUserDataValid}, errorKey: ${errorKey}`);
        } else if (!field.path.includes('[*]') && conditionInBrackets(field.path)) {
          const valueWasFound: boolean = findValue(field.path, userData);
          if (!valueWasFound) {
            isValid = false;
            areUserDataValid = false;
            field.translation_key && (errorKey = field.translation_key);
          } else {
            isValid = true;
          }
        }
        isValid = false;
      }
      return {isValid: areUserDataValid, ...(!areUserDataValid && {errorKey: `mandatoryFields.${errorKey}`})};
    }
    return {isValid: false, errorKey: ''};
  }
  return {isValid: true};
}

/**
 * Input str: attributes[label=='KRONOS Instance']<br>
 * Output: {<br>
 *   pathToIter: 'attributes'  <br>
 *   fieldToCheck: 'label'     <br>
 *   value: 'KRONOS Instance'  <br>
 * }<br>
 * At least one field must equal the value
 * @param str
 */
export function getPathFieldValueToCheck(str: string): {
  pathToIter: string;
  fieldToCheck: string;
  value: string;
  opIndex: number;
} {
  logd(`getPathFieldValueToCheck str: ${str}`);
  let opIndex = 0;
  const condition = str.substring(str.indexOf('[') + 1, str.indexOf(']'));
  operators.forEach((op, index) => condition.includes(op) && (opIndex = index));
  const pathToIter: string = str.substring(0, str.indexOf('['));
  logd(`condition.split(operators[opIndex]): `, condition.split(operators[opIndex]));
  const fieldToCheck: string = condition.split(operators[opIndex])[0].trim().replace(/['"]+/g, '');
  logd(`condition.split(operators[opIndex])[1]: ${condition.split(operators[opIndex])[1]}`);
  const value: string = condition.split(operators[opIndex])[1].trim().replace(/['"]+/g, '');
  return {pathToIter, fieldToCheck, value, opIndex};
}

function findValue(path: string, userData: any, pathFirstPart?: string): boolean {
  let valueWasFound = false;
  const res = getPathFieldValueToCheck(path);
  const fullPath = (!!pathFirstPart ? pathFirstPart + '.' : '') + res.pathToIter;
  const dataArr = get(userData, fullPath);
  //logd(`findValue fullPath: ${fullPath} res: `, res);
  if (dataArr != null && Array.isArray(dataArr)) {
    for (let k = 0; k < dataArr.length && !valueWasFound; k++) {
      const fieldPath = fullPath + '[' + k + '].' + res.fieldToCheck;
      const dataField = get(userData, fieldPath);
      //logd(`findValue fieldPath: ${fieldPath} dataField: `, dataField);
      const opIndex = res.opIndex;
      if (opIndex === 0 && dataField == res.value) {
        // ==
        valueWasFound = true;
      } else if (opIndex === 1 && dataField != res.value) {
        // !=
        valueWasFound = true;
      } else if (opIndex === 2 && dataField > res.value) {
        // >
        valueWasFound = true;
      } else if (opIndex === 3 && dataField < res.value) {
        // <
        valueWasFound = true;
      } else if (opIndex === 4 && dataField >= res.value) {
        // >=
        valueWasFound = true;
      } else if (opIndex === 5 && dataField <= res.value) {
        // <=
        valueWasFound = true;
      }
    }
  }
  return valueWasFound;
}
