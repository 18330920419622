import React, {Component} from 'react';
import {Text, View, TextInput, Keyboard, ActivityIndicator, Dimensions} from 'react-native';
import {connect} from 'react-redux';
import {styles, dhlBasicOrange, dhlBlack, dhlLightOrange, dhlWhiteBasic, dhlGrey} from '../component/commonStyles';
import I18n from 'features/I18n';
import {style} from './LoginScreen';
import LinearGradient from 'react-native-linear-gradient';
import {ScaledSheet} from 'react-native-size-matters';
import {ActionButton, navigationPopToAction} from '@smartops/smartops-shared';
import {changePinAction, checkPassword} from '../flows/auth';
import {logi} from 'features/logging';
import {isDeviceOnline, isWeb, showToast} from '../features/platformSpecific';

const passRegex =
  '^(?=.{8,})((?=.*[^a-zA-Z\\s])(?=.*[a-z])(?=.*[A-Z])|(?=.*[^a-zA-Z0-9\\s])(?=.*\\d)(?=.*[a-zA-Z])).*$';

class SetPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: null,
      newPassword: null,
      newPasswordVer: null,
      error: props.error,
      isLoading: false,
      showOldPassword: !props.oldPassword,
      counter: 0,
    };
  }

  componentDidUpdate(previousProps) {
    if (this.props.attemptNumber !== previousProps.attemptNumber) {
      this.setState({error: this.props.error, isLoading: false});
    }
  }

  componentDidMount() {
    if (isWeb) {
      window.visualViewport.addEventListener('resize', e => this.increaseCounter());
    } else {
      Keyboard.addListener('keyboardDidShow', () => this.setState({error: null}));
    }
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <View style={{paddingBottom: 12}}>
          <Text style={style.errorText} accessibilityLabel="change_pin_error">
            {this.state.error}
          </Text>
        </View>
      );
    }
  };

  onOldPassChange = text => this.setState({oldPassword: text, error: null});
  onPassChange = text => this.setState({newPassword: text, error: null});
  onPassVerChange = text => this.setState({newPasswordVer: text, error: null});
  increaseCounter = () => this.setState({counter: this.state.counter + 1});

  onSubmit = () => {
    isDeviceOnline().then(isOnline => {
      if (isOnline) {
        const attemptNumber = this.props.attemptNumber || 1;
        const {isPinAuth, isPwdAuth, previousScreen, changePinAction} = this.props;
        Keyboard.dismiss();
        const {oldPassword, newPassword, newPasswordVer, showOldPassword} = this.state;
        logi(`newPassword?.matches(passRegex) ${newPassword?.match(passRegex)}`);
        if (isPinAuth && (isNaN(newPassword) || isNaN(newPasswordVer))) {
          const error = I18n.t('pinReset.notANumber');
          this.setState({isLoading: false, error});
          return;
        }
        const isPwdValid = newPassword?.match(passRegex) != null;
        // First Check if old password is correct
        checkPassword(oldPassword).then(isOldPinValid => {
          if (showOldPassword && !isOldPinValid) {
            const error = I18n.t(isPinAuth ? 'pinInitial.wrongOldPin' : 'pwdInitial.wrongOldPin');
            this.setState({isLoading: false, error});
          } else if (
            (oldPassword &&
              ((isPinAuth && oldPassword.length === 6) || (isPwdAuth && isPwdValid)) &&
              newPassword === oldPassword) ||
            (this.props.oldPassword &&
              ((isPinAuth && this.props.oldPassword.length === 6) || isPwdAuth) &&
              newPassword === this.props.oldPassword)
          ) {
            // Secondly check that old pass and new pass are not the same
            const error = I18n.t(isPinAuth ? 'pinReset.error' : 'pwdReset.error');
            this.setState({isLoading: false, error});
          } else if (
            newPassword &&
            ((isPinAuth && newPassword.length === 6) || (isPwdAuth && isPwdValid)) &&
            newPassword === newPasswordVer
          ) {
            this.setState({isLoading: true});
            changePinAction({
              oldPassword: this.props.oldPassword || oldPassword, // on init password we receive old pwd in props
              newPassword: newPassword,
              isInitial: !this.state.showOldPassword,
              attemptNumber: attemptNumber + 1,
              previousScreen: previousScreen,
            });
          } else {
            let error;
            if (isPinAuth) {
              error =
                newPassword && newPassword.length === 6
                  ? I18n.t('pinInitial.noMatch')
                  : I18n.t('pinInitial.wrongLength');
            } else {
              if (newPassword && newPassword.length < 8) {
                error = I18n.t('pwdInitial.wrongLength');
              } else if (newPassword && newPassword.length >= 8 && isPwdValid) {
                error = I18n.t('pwdInitial.noMatch');
              } else {
                error = I18n.t('pwdInitial.invalid');
              }
            }
            this.setState({isLoading: false, error});
          }
        });
      } else {
        showToast(I18n.t('fileTransport.offlineModeError'));
      }
    });
  };

  render() {
    const {isPinAuth, navigationPopToAction} = this.props;
    const {showOldPassword, oldPassword, newPassword, newPasswordVer, isLoading, error, counter} = this.state;
    const {scale, width, height, fontScale} = Dimensions.get('window');
    const maxHeight = height;
    let widthStyle = {};
    if (width < 400 && showOldPassword) {
      // Handle small devices
      logi(`>>> width < 400 scale: ${scale} width: ${width} height: ${height} fontScale: ${fontScale}`);
      widthStyle = ScaledSheet.create({
        maxW: {
          minWidth: '130@ms0.2',
          maxWidth: '130@ms0.2',
        },
        minW: {
          minWidth: '250@ms0.2',
        },
      });
    }
    return (
      <LinearGradient
        colors={[dhlBasicOrange, dhlLightOrange]}
        style={[styles.mainContainer, scaledStyleLocal.mainContainer, {maxHeight: maxHeight}]}>
        <Title requireOldPass={showOldPassword} isPinAuth={isPinAuth} />
        {showOldPassword && (
          <TextInput
            style={scaledStyleLocal.input}
            accessibilityLabel="old_pin_input"
            underlineColorAndroid="rgba(0,0,0,0)"
            autoCapitalize="none"
            onSubmitEditing={() => this.newPasswordInput.focus()}
            autoCorrect={false}
            keyboardType={isPinAuth ? 'numeric' : 'default'}
            returnKeyType="next"
            placeholder={I18n.t(isPinAuth ? 'pinReset.original' : 'pwdReset.original')}
            placeholderTextColor="#323232"
            blurOnSubmit={false}
            maxLength={256}
            secureTextEntry
            value={oldPassword || ''}
            onChangeText={this.onOldPassChange}
          />
        )}

        <TextInput
          style={scaledStyleLocal.input}
          accessibilityLabel="new_pin_input1"
          underlineColorAndroid="rgba(0,0,0,0)"
          autoCapitalize="none"
          onSubmitEditing={() => this.newPasswordInputVer.focus()}
          ref={input => (this.newPasswordInput = input)}
          autoCorrect={false}
          keyboardType={isPinAuth ? 'numeric' : 'default'}
          returnKeyType="next"
          placeholder={I18n.t(isPinAuth ? 'pinInitial.enter' : 'pwdInitial.enter')}
          placeholderTextColor="#323232"
          blurOnSubmit={false}
          maxLength={256}
          secureTextEntry
          value={newPassword || ''}
          onChangeText={this.onPassChange}
        />

        <TextInput
          style={scaledStyleLocal.input}
          accessibilityLabel="new_pin_input2"
          underlineColorAndroid="rgba(0,0,0,0)"
          autoCorrect={false}
          autoCapitalize="none"
          returnKeyType="go"
          keyboardType={isPinAuth ? 'numeric' : 'default'}
          ref={input => (this.newPasswordInputVer = input)}
          onSubmitEditing={this.onSubmit}
          placeholder={I18n.t(isPinAuth ? 'pinInitial.confirm' : 'pwdInitial.confirm')}
          placeholderTextColor="#323232"
          maxLength={256}
          onChangeText={this.onPassVerChange}
          value={newPasswordVer || ''}
          secureTextEntry
        />

        {!isLoading || error ? (
          <View style={[scaledStyleLocal.buttonsRow, widthStyle.minW, !showOldPassword && {justifyContent: 'center'}]}>
            {showOldPassword && (
              <ActionButton
                onPress={() => navigationPopToAction('pageMain')}
                accessibilityLabel="change_pin_cancel"
                title={I18n.t('common.cancel')}
                containerViewStyle={[{width: '40%'}, scaledStyleLocal.button, widthStyle.maxW]}
                textStyle={{color: dhlWhiteBasic, fontWeight: '600'}}
                backgroundColor={dhlGrey}
              />
            )}
            <ActionButton
              type={'Primary'}
              onPress={this.onSubmit}
              accessibilityLabel="change_pin_set_new_pin"
              title={I18n.t(isPinAuth ? 'pinInitial.set' : 'pwdInitial.set')}
              containerViewStyle={[
                {
                  width: showOldPassword ? '40%' : '60%',
                },
                scaledStyleLocal.button,
                widthStyle.maxW,
              ]}
              textStyle={{color: dhlWhiteBasic, fontWeight: '600'}}
            />
          </View>
        ) : (
          <ActivityIndicator size={'small'} />
        )}
        <View style={style.errorContainer}>{this.renderError()}</View>
      </LinearGradient>
    );
  }
}

function Title(props) {
  if (props.requireOldPass) {
    return (
      <Text style={scaledStyleLocal.textStyle}>{I18n.t(props.isPinAuth ? 'pinReset.title' : 'pwdReset.title')}</Text>
    );
  }
  return (
    <Text style={scaledStyleLocal.textStyle}>{I18n.t(props.isPinAuth ? 'pinInitial.title' : 'pwdInitial.title')}</Text>
  );
}

const scaledStyleLocal = ScaledSheet.create({
  input: {
    height: '48@ms0.2',
    paddingLeft: '4@ms0.2',
    width: '66%',
    maxWidth: '270@ms0.2',
    backgroundColor: dhlWhiteBasic,
    marginBottom: '2@ms0.2',
    color: dhlBlack,
    borderRadius: 4,
    fontSize: '16@ms0.2',
  },
  button: {
    minWidth: '160@ms0.2',
    maxWidth: '260@ms0.2',
  },
  buttonsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
    minWidth: '350@ms0.2',
    maxWidth: '450@ms0.2',
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  textStyle: {
    fontSize: '24@ms0.2',
    color: dhlBlack,
    textAlign: 'center',
  },
});

function mapStateToProps(state) {
  return {
    isPinAuth: state.auth.user.authType === 'authenticationType.pinAuth',
    isPwdAuth: state.auth.user.authType === 'authenticationType.pwdAuth',
  };
}

export default connect(mapStateToProps, {changePinAction, navigationPopToAction})(SetPasswordScreen);
