import {AuthenticationException, ConcurrentException, WSCallException} from '../exception/CustomExceptions';
import {OAUTH_REFRESH_TOKEN_URL, OAUTH_SECRET_PASSWORD, OAUTH_SECRET_USERNAME} from '../config';
import Base64 from './Base64';
import I18n from 'features/I18n';
import {doFetch} from './fetch';
import {logObject} from './Utils';
import {call, put, select} from 'redux-saga/effects';
import {KEY_ACCESS_TOKEN, KEY_REFRESH_TOKEN, loadValue, saveValue} from 'features/sharedPrefs';
import {getAccessTokenSelector} from 'features/selectors';
import {log, messageLogger} from '@smartops/smartops-shared';
import {storeTokensAction} from '../flows/auth';
import Metrics from 'features/metrics';
import {isWeb} from '../features/platformSpecific';

let globalRequestCounter = 1; // just for logging purposes

let lastRefreshTokenDate = new Date(0); // Initialize date value 1970...

const checkStatus = (response, logCounter, authExceptionFor403 = false, refreshTokenOnly = false) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  log.info(
    `Api call ended #${logCounter} with status ${response.status} [${response.statusText}] , authExceptionFor403: ${authExceptionFor403}`,
  );
  if (response.status === 401 || (authExceptionFor403 && response.status === 403)) {
    let error = new Error(response.statusText);
    throw new AuthenticationException(error, 'Oauth2 token expired', response.status);
  }
  if (refreshTokenOnly !== true) {
    let error = new Error(response.statusText);
    throw new WSCallException(
      error,
      `Unexpected WS status code: ${response.status} for request ${logCounter}`,
      response.status,
    );
  }
};

const parseJSON = async response => {
  return await response.json();
};

export const apiCallWithoutRefreshToken = async options => {
  const {
    params,
    accessToken,
    headers,
    requestCounter,
    method = 'GET',
    wantResponse = 'JSON',
    body,
    timeout = 60000,
    addStatus,
    canUseRefreshToken,
    ignoreMessageLog = false,
  } = options;
  let {url} = options;
  if (params) {
    let urlParams = [];
    for (let property in params) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(params[property]);
      urlParams.push(encodedKey + '=' + encodedValue);
    }
    url = url + '?';
    url = url + urlParams.join('&');
  }
  log.info(`apiCall ${method} request #${requestCounter} ${url}`);
  const requestHeaders = headers || {};
  requestHeaders.Authorization = 'Bearer ' + accessToken;
  const fetchParams = {
    headers: requestHeaders,
    timeout: timeout,
    method: method,
  };
  if (body) {
    fetchParams.body = body;
  }
  !ignoreMessageLog && messageLogger.request(requestCounter, url, fetchParams);
  const response = await doFetch(url, fetchParams);
  log.info(`apiCall request #${requestCounter} received response with status ${response.status}`);
  messageLogger.response(requestCounter, response);
  checkStatus(response, requestCounter, canUseRefreshToken, addStatus);
  if (wantResponse === 'JSON') {
    const responseObject = await parseJSON(response);
    messageLogger.responseBody(requestCounter, JSON.stringify(responseObject));
    notThatVerboseLog(`Response #${requestCounter}: `, responseObject);
    return addStatus ? {status: response.status, body: responseObject} : responseObject;
  }
  return response;
};

export const notThatVerboseLog = (message, object) => {
  log.debug(`${message}${logObject(object)}`);
};

const revokeToken = async (url, accessToken) => {
  log.info(`revokeToken url: ${url} AT: ${accessToken}`);
  let response = await doFetch(url, {
    timeout: 5000,
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
  });
  log.debug(`revokeToken response: ${response?.status}`);
  return response;
};

export async function apiCallPromise(options) {
  const {accessToken} = options;
  const newOptions = {...options, canUseRefreshToken: true};
  if (!accessToken) {
    newOptions.accessToken = await loadValue(KEY_ACCESS_TOKEN);
  }
  return apiCallWithAccessToken(newOptions);
}

export function* apiCall(options) {
  const {accessToken} = options;
  const newOptions = {...options};
  if (!accessToken) {
    newOptions.accessToken = yield select(getAccessTokenSelector);
  }
  const accessTokenShared = yield call(loadValue, KEY_ACCESS_TOKEN);
  if (accessTokenShared != null && newOptions.accessToken && newOptions.accessToken !== accessTokenShared) {
    const refreshTokenShared = yield call(loadValue, KEY_REFRESH_TOKEN);
    newOptions.accessToken = accessTokenShared;
    log.debug(`Loading new AT and RT to redux...`);
    yield put(storeTokensAction(accessTokenShared, refreshTokenShared));
  }
  return yield call(apiCallWithAccessToken, newOptions);
}

export async function apiCallWithAccessToken(options) {
  const requestCounter = options.requestCounter ? options.requestCounter : globalRequestCounter++;
  const {accessToken} = options;
  if (options.secondTry) {
    log.info(`Second try of request #${requestCounter} with refreshed access token ${accessToken}`);
  }
  try {
    return await apiCallWithoutRefreshToken({...options, accessToken, requestCounter});
  } catch (error) {
    if (
      error instanceof AuthenticationException &&
      !options.secondTry &&
      (error.statusCode === 401 || error.statusCode === 403)
    ) {
      log.debug('apiCallWithAccessToken authentication error: ' + JSON.stringify(error));
      log.info(`Going to refresh expired access token ${accessToken} for request #${requestCounter}`);
      let refreshResult = {};
      let currentTime = new Date();
      if ((currentTime.getTime() - lastRefreshTokenDate.getTime()) / 1000 <= 1) {
        // Wait to get the new token from a previous call
        log.debug(`Not refreshing this`, (currentTime.getTime() - lastRefreshTokenDate.getTime()) / 1000);
        log.info(
          `Refresh token for request #${requestCounter} ignored because of concurrent refresh already started. Waiting 1.5s`,
        );
        await sleep(1500);
        log.debug(`Slept for 1500`);
        refreshResult.accessToken = await loadValue(KEY_ACCESS_TOKEN);
      } else {
        lastRefreshTokenDate = new Date();
        refreshResult = await refreshToken(requestCounter);
      }
      if (refreshResult.accessToken) {
        return await apiCallWithAccessToken({
          ...options,
          secondTry: true,
          requestCounter,
          accessToken: refreshResult.accessToken,
        });
      }
      throw new AuthenticationException(I18n.t('errors.authExpired'));
    } else if (error instanceof ConcurrentException || error instanceof AuthenticationException) {
      throw error;
    } else {
      log.info(`apiCallWithAccessToken url: ${options.url} error: `, JSON.stringify(error));
      throw new WSCallException(error, I18n.t('main.serverError'));
    }
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const timeout = (cb, interval) => {
  return new Promise(resolve => setTimeout(() => cb(resolve), interval));
};

const onTimeout = timeout(resolve => resolve('AsyncStorage more than 250ms'), 250);

export const refreshToken = async requestCounter => {
  const RT = await loadValue(KEY_REFRESH_TOKEN);
  try {
    const params = {
      refresh_token: RT,
    };
    log.info(`Refreshing token for request #${requestCounter} with rt ${RT}`);
    const response = await postParamsWithJSONResponse(OAUTH_REFRESH_TOKEN_URL, params, requestCounter);
    await saveValue(KEY_REFRESH_TOKEN, response.refresh_token);
    await saveValue(KEY_ACCESS_TOKEN, response.access_token);
    log.info(
      `Refreshing token response for request #${requestCounter}: got AT ${response.access_token} and RT ${response.refresh_token}`,
    );
    return {accessToken: response.access_token, refreshToken: response.refresh_token};
  } catch (error) {
    log.info(`Refresh token for #${requestCounter} error:${JSON.stringify(error)}`);
    if (isWeb) {
      await Metrics.trackEvent(Metrics.EventType.AutomaticLogout);
    }
    await saveValue(KEY_ACCESS_TOKEN, null);
    await saveValue(KEY_REFRESH_TOKEN, null);
    if (isWeb) {
      await Metrics.storeSessionId(undefined);
    }
    return {isOk: false};
  }
};

const postParamsWithJSONResponse = async (url, params, requestCounter) => {
  let formBody = [];
  for (let property in params) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  formBody = formBody.join('&');
  const fetchOptions = {
    timeout: 10000,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + Base64.btoa(OAUTH_SECRET_USERNAME + ':' + OAUTH_SECRET_PASSWORD),
    },
    body: formBody,
  };
  messageLogger.request(requestCounter, url, fetchOptions);
  const response = await doFetch(url, fetchOptions);
  messageLogger.response(requestCounter, response);
  log.info(`Post executed for request #${requestCounter} with response status ${response.status}`);
  checkStatus(response, requestCounter);
  const tokens = await parseJSON(response);
  messageLogger.responseBody(requestCounter, JSON.stringify(tokens));
  return tokens;
};

export const revokeTokenFromUm = async (accessToken, url) => {
  try {
    return await revokeToken(url, accessToken);
  } catch (error) {
    return {
      ok: false,
      status: 404,
    };
  }
};
