import {select, all, call, put, take} from 'redux-saga/effects';
import {
  KEY_APP_SETTINGS,
  KEY_APPS_DETAILS,
  KEY_FAVORITE_APPS_KEYS,
  loadJson,
  loadValue,
  saveValue,
} from '../features/sharedPrefs';
import appBbs from '../features/apps/bbs';
import appBrowser from '../features/apps/browser';
import appGemba from '../features/apps/gemba';
import appIndApr from '../features/apps/indapr';
import appMKronos from '../features/apps/mkronos';
import appMLogics from '../features/apps/mlogics';
import appMPts from '../features/apps/mpts';
import appMWlm from '../features/apps/mwlm';
import appTdmKpi from '../features/apps/tdmkpi';
import appProcConf from '../features/apps/procconf';
import appKioskBrowser from '../features/apps/kioskbrowser';
import appIncMng from '../features/apps/incmng';
import appPerfRev from '../features/apps/perfrev';
import app306090 from '../features/apps/review306090';
import appChecklist from '../features/apps/checklist';
import appCiTracker from '../features/apps/citracker';
import {logd, logi} from '../features/logging';
import {getUserAppsSelector, getUserSelector} from '../features/selectors';
import {getAppsFromGqlUm} from './auth';
import * as RNFS from 'react-native-fs';
import {isWeb} from '../features/platformSpecific';
import {getAvailableWebAppKeysSelector} from './app';

export const ALL_APPS = [
  appBbs,
  appBrowser,
  appGemba,
  appIndApr,
  appMKronos,
  appMLogics,
  appMPts,
  appMWlm,
  appTdmKpi,
  appKioskBrowser,
  appProcConf,
  appIncMng,
  appPerfRev,
  app306090,
  appChecklist,
  appCiTracker,
];

// Actions
const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE';
const STORE__APPS_DETAILS = 'STORE__APPS_DETAILS';
const STORE__FAVORITE_APPS_KEYS = 'STORE__FAVORITE_APPS_KEYS';
const CHECK__RUNNING_APPS_STATUS = 'CHECK__RUNNING_APPS_STATUS';
const SET__RUNNING_APPS_KEYS = 'SET__RUNNING_APPS_KEYS';

// Reducer
export default function appsReducer(
  state = {
    favoriteAppsKeys: [],
    appsDetails: undefined,
    runningAppsKeys: [],
  },
  action = {},
) {
  switch (action.type) {
    case STORE__APPS_DETAILS:
      return {...state, appsDetails: action.payload};
    case STORE__FAVORITE_APPS_KEYS:
      return {...state, favoriteAppsKeys: action.payload};
    case SET__RUNNING_APPS_KEYS:
      return {...state, runningAppsKeys: action.payload};
    default:
      return state;
  }
}

// selectors
export const getFavoriteAppsKeySelector = state => state.apps.favoriteAppsKeys;
export const getAppsDetailsSelector = state => state.apps.appsDetails;

// action creators
export const storeAppsDetailsAction = payload => ({type: STORE__APPS_DETAILS, payload});
export const storeFavoriteAppsKeysAction = payload => ({type: STORE__FAVORITE_APPS_KEYS, payload});
export const toggleFavoriteAction = key => ({type: TOGGLE_FAVORITE, payload: key});
export const checkRunningAppsStatusAction = () => ({type: CHECK__RUNNING_APPS_STATUS});

export function* favoriteAppsWatcher() {
  while (true) {
    const action = yield take(TOGGLE_FAVORITE);
    const newFav = action.payload; //New array with the current favorite apps
    yield put(storeFavoriteAppsKeysAction(newFav));
    yield call(saveFavoritesAppsKeysToSharedPrefs);
  }
}

export function* getAppsDetailsFromUm() {
  const umAppsGqlResponse = yield call(getAppsFromGqlUm);
  if (umAppsGqlResponse && umAppsGqlResponse.data && umAppsGqlResponse.data.allApplications) {
    const apps = umAppsGqlResponse.data.allApplications.filter(app => app.mobile === true);
    apps.sort((a, b) => a.order - b.order);
    const appsForRedux = apps.map(app => ({
      key: app.label,
      name: app.name,
      image: 'data:image/png;base64,' + app.logo,
      url: app.url,
      linkEnable: false,
      order: app.order,
    }));
    return appsForRedux;
  }
}

export function* saveAppsDetailsToSharedPrefs() {
  const appsDetails = yield select(getAppsDetailsSelector);
  if (appsDetails) {
    yield call(saveValue, KEY_APPS_DETAILS, JSON.stringify(appsDetails));
  }
}

export function* loadAppsDetailsFromSharedPrefsAndStoreToRedux() {
  const appsDetails = JSON.parse(yield call(loadValue, KEY_APPS_DETAILS));
  yield put(storeAppsDetailsAction(appsDetails));
}

export function* saveFavoritesAppsKeysToSharedPrefs() {
  const currentFavoriteKeys = yield select(getFavoriteAppsKeySelector);
  yield call(saveValue, KEY_FAVORITE_APPS_KEYS, JSON.stringify(currentFavoriteKeys));
}

export function* loadFavoriteAppsKeysFromSharedPrefsAndStoreToRedux() {
  const favorites = JSON.parse(yield call(loadValue, KEY_FAVORITE_APPS_KEYS));
  yield put(storeFavoriteAppsKeysAction(favorites));
}

export function* fetchAndStoreInitData() {
  const user = yield select(getUserSelector);
  const userApps = yield select(getUserAppsSelector);
  const availableWebAppKeys = yield select(getAvailableWebAppKeysSelector);
  const userAppsKeys = Object.keys(userApps);
  logi(`Fetching init data for user ${user.login}`);
  const allEffects = ALL_APPS.filter(app => app.needInitData)
    .filter(app => userAppsKeys.includes(app.label))
    .filter(app => (isWeb ? availableWebAppKeys.includes(app.label.toLowerCase()) : true))
    .map(app => call(app.loadInitData, user, userApps[app.label]));
  yield all(allEffects);
}

export function* clearAppsInitDataFromSharedPrefs() {
  yield all(ALL_APPS.filter(app => app.needInitData).map(app => call(saveValue, app.initDataKey, null)));
}

export function* resetAllAppStatuses() {
  const task = JSON.stringify({isActive: false});
  yield all(ALL_APPS.filter(app => !!app.activeTaskKey).map(app => call(saveValue, app.activeTaskKey, task)));
}

export function* setRestartFlagsToTrue() {
  yield all(ALL_APPS.map(app => call(saveValue, app.restartKey, 'true')));
}

export function* removeWmsFilesIfTheyExist() {
  if (isWeb) return;
  let filenameToUse, storageLocation;
  const scanners = ['wms_scanner', 'smart_scanner'];
  for (let scanner of scanners) {
    const fileName = yield call(getAppSettingsValue, `${scanner}.sso_file_name`);
    if (fileName != null) {
      filenameToUse = `${fileName}.json`;
    } else {
      filenameToUse = scanner === 'smart_scanner' ? 'smart_scanner_sso.json' : 'velocity_scanner_sso.json';
    }
    if (scanner === 'smart_scanner') {
      storageLocation = yield call(getAppSettingsValue, `${scanner}.wms_sso_file_destination_path`);
    } else if (scanner === 'wms_scanner') {
      storageLocation = yield call(getAppSettingsValue, `${scanner}.sso_file_destination_path`);
    }
    if (storageLocation) {
      const fullFilename = `${storageLocation}/${filenameToUse}`;
      logi(`Removing file: ${fullFilename}`);
      try {
        yield call(RNFS.unlink, fullFilename);
        logi(`File ${fullFilename} deleted`);
      } catch (err) {
        logi(`Error deleting file ${fullFilename}`, err.message);
      }
    } else {
      logi(`storageLocation is not available, files cannot be removed`);
    }
  }
}

export function* getAppSettingsValue(key) {
  let appSettings;
  try {
    appSettings = yield call(loadJson, KEY_APP_SETTINGS);
  } catch (e) {
    logi('Error in getAppSettingsValue: ', e);
  }
  let value;
  if (appSettings && appSettings.portal) {
    value = appSettings.portal[key];
  }
  return value;
}

export async function getAppSettingsVal(key) {
  let appSettings;
  try {
    appSettings = await loadJson(KEY_APP_SETTINGS);
  } catch (e) {
    logi('Error in getAppSettingsVal: ', e);
  }
  let value;
  if (appSettings && appSettings.portal) {
    value = appSettings.portal[key];
  }
  return value;
}

export function* runningAppsStatusWatcher() {
  while (true) {
    yield take(CHECK__RUNNING_APPS_STATUS);
    const appsWithActiveTask = [];
    for (const app of ALL_APPS) {
      if (app.activeTaskKey) {
        const activeTask = JSON.parse(yield call(loadValue, app.activeTaskKey));
        if (activeTask && activeTask.isActive) {
          appsWithActiveTask.push(app.label);
        }
      }
    }
    //logi(`appsWithActiveTask: ${JSON.stringify(appsWithActiveTask)}`);
    yield put({type: SET__RUNNING_APPS_KEYS, payload: appsWithActiveTask});
  }
}
